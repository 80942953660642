<template>
  <div class="container__footer">
    <div class="footer-2">
      <div class="footer-2__linkbox">
        <div class="footer-2__linkbox__item">
          <img src="@/assets/desktop/img/footer-2-1.svg" alt="找不到图片！">
          <span class="footer-2__linkbox__item__text">专业的数据分析网站</span>
        </div>
        <div class="footer-2__linkbox__item">
          <img src="@/assets/desktop/img/footer-2-2.svg" alt="找不到图片！">
          <span class="footer-2__linkbox__item__text">品质保证</span>
        </div>
        <div class="footer-2__linkbox__item">
          <img src="@/assets/desktop/img/footer-2-3.svg" alt="找不到图片！">
          <span class="footer-2__linkbox__item__text">全方面的数据资料</span>
        </div>
        <div class="footer-2__linkbox__item">
          <img src="@/assets/desktop/img/footer-2-4.svg" alt="找不到图片！">
          <span class="footer-2__linkbox__item__text">即时更新</span>
        </div>
      </div>
      <div class="footer-2__copyright">
        <span class="footer-2__copyright__text">免责声明: 本站内容仅作参考交流，不进行任何现金交易行为。</span>
        <span class="footer-2__copyright__text">本网不承担由内容的合法性所引起的一切争议与法律责任。</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebFooter'
}
</script>
