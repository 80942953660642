import request from '@/utils/request'

// 走勢 api - flyc & James

export const getMultiTrend_web = function(cpCode, trendTypes, query) {
  const url = `/v1/cp168/apis/cpTrend/${cpCode}`
  const params = Object.assign({}, query, { trendTypes })
  return request({
    url,
    params
  })
}

export const getMissingTrend = function(cpCode, trendTypes, query) {
  const url = `/v1/cp168/apis/cpTrend/${cpCode}/${trendTypes}`
  const params = Object.assign({}, query)
  return request({
    url,
    params
  })
}

// 走勢圖表
export const getCpsList_web = function(cpType) {
  return request({
    url: `/v1/cp168/apis/cps?cpType=${cpType}`,
    method: 'get'
  })
}

export const getRuleDetails_web = function(cpCode, query) {
  return request({
    url: `/v1/apis/ruleDetails/${cpCode}`,
    method: 'get',
    params: query
  })
}

export const getTrand = function(cpCode, query) {
  return request({
    url: `/v1/cp168/apis/ruleDetails/${cpCode}`,
    method: 'get',
    params: query
  })
}
