<template>
  <div class="container fn-min-height">
    <WebHeader />
    <nuxt />
    <WebFooter />
    <div v-show="isScrolled" class="fixed-position">
      <fix-top-button @click.native="scrollToTop" />
    </div>
    <div class="modal">
      <div class="popup" :class="{active: videoState.active ||descriptionState.active}">
        <div v-if="videoState.active" class="popup__box">
          <div class="popup__box__title">
            <div class="pop-title">
              <div class="pop-title__left">
                <span class="pop-title__left__text">{{ videoState.name }}</span>
              </div>
              <div class="pop-title__right">
                <div class="pop-close" @click="closeVideo">
                  <img src="@/assets/desktop/img/close.svg" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="popup__box__content">
            <PopUpVideo :lottery-code="videoState.lotteryCode" /></div>
        </div>
        <div v-if="descriptionState.active" class="popup__box w660">
          <div class="popup__box__title">
            <div class="pop-title">
              <div class="pop-title__left">
                <span class="pop-title__left__text">{{ descriptionState.name }}</span>
              </div>
              <div class="pop-title__right">
                <div class="pop-close" @click="closeDescription">
                  <img src="@/assets/desktop/img/close.svg" alt="">
                </div>
              </div>
            </div>
          </div>
          <RulePopUp />
        </div>
      </div>
      <SidebarMenu />
    </div>
  </div>
</template>

<script>
import WebHeader from '@/components/web/layout/WebHeader'
import WebFooter from '@/components/web/layout/WebFooter'
import PopUpVideo from '@/components/web/common/PopUpVideo'
import SidebarMenu from '@/components/web/common/SidebarMenu'
import RulePopUp from '@/components/web/pages/trend/RulePopUp'
import fixTopButton from '@/components/web/common/fixTopButton'
export default {
  components: {
    WebHeader,
    PopUpVideo,
    SidebarMenu,
    RulePopUp,
    fixTopButton,
    WebFooter
  },
  data() {
    return {
      toTopBtnStyle: {
        bottom: '32px',
        right: ''
      },
      isScrolled: false
    }
  },
  computed: {
    videoState() {
      return this.$store.state.lottery.video
    },
    descriptionState() {
      return this.$store.state.lottery.description
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.$store.dispatch('member/checkLocalStorage_web')
      }
    }
  },
  mounted() {
    const vm = this
    this.$store.dispatch('member/checkLocalStorage_web')
    window.onscroll = function() {
      vm.isScrolled = window.scrollY > 0
    }
  },
  methods: {
    closeVideo() {
      this.$store.commit('lottery/CLOSE_VIDEO')
    },
    closeDescription() {
      this.$store.commit('lottery/CLOSE_DESCRIPTION')
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  head() {
    return {
      link: [
        { rel: 'stylesheet', href: '/desktop/css/main.css' }
      ],
      title: `${this.$store.state.site.siteInfo.siteName}`,
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-position {
  position: fixed;
  bottom: 32Px;
  right: calc(50% - 600Px - 40Px - 32Px);
}
.fn-min-height {
  min-height: 100vh;
}
@media screen and (max-width: 1242px){
  .fixed-position {
    right: 32Px;
  }
}
</style>
