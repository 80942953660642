<template>
  <div class="layout-main" style="font-size: 16px;">
    <index-header />
    <nuxt />
    <wap-footer />
  </div>
</template>

<script>
import IndexHeader from '@/components/mobile/layout/IndexHeader'
import WapFooter from '@/components/mobile/layout/WapFooter'
export default {
  name: 'MemberIndexLayout',
  components: {
    IndexHeader,
    WapFooter
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.$store.dispatch('member/checkLocalStorage_mobile')
      }
    }
  },
  mounted() {
    this.$store.dispatch('member/checkLocalStorage_mobile')
  },
  head() {
    return {
      style: [
        { type: 'text/css', cssText: require('@/assets/style/css/main.css') }
      ],
      titleTemplate: `我的 | ${this.$store.state.site.siteInfo.siteName}`,
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
    }
  }
}
</script>
