import { getSiteInfo_mobile } from '@/api/mobile/basic'
import { getSiteInfo_desktop } from '@/api/desktop/basic'

const state = () => ({
  siteInfo: {},
  title: '',
  webSiteInfo: {},
  webTitle: '',
  tuku: {},
  webTuku: {},
  webHotLotteryTab: [],
  wapHotLotteryTab: []
})

const mutations = {
  SET_SITEINFO: (state, payload) => {
    state.siteInfo = Object.assign({}, state.siteInfo, payload)
    state.tuku = Object.assign({}, state.tuku, payload)
    state.wapHotLotteryTab = Object.assign([], state.siteInfo.siteSetting.hotLotteryTab)
  },
  SET_TITLE: (state, payload) => {
    state.title = payload
  },
  SET_WEB_SITEINFO: (state, payload) => {
    state.webSiteInfo = Object.assign({}, state.webSiteInfo, payload)
    state.webHotLotteryTab = Object.assign([], state.webSiteInfo.siteSetting.homePageLotteries.hotLotteryTab)
  },
  SET_WEB_TITLE: (state, payload) => {
    state.webTitle = payload
  }
}

const actions = {
  async getSiteInfo({ commit }) {
    const [siteInfo, error] = await getSiteInfo_mobile()
    if (error) return
    commit('SET_SITEINFO', siteInfo)
  },
  async getWebSiteInfo({ commit }) {
    const [siteInfo, error] = await getSiteInfo_desktop()
    if (error) return
    commit('SET_WEB_SITEINFO', siteInfo)
  }
}

export { state, mutations, actions }
