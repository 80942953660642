<template>
  <client-only>
    <div class="nav">
      <div class="nav__left">
        <img
          class="setting-icon"
          src="@/assets/style/img/member/combinedShape@2x.png"
          @click="$router.push('/m/member/setting')"
        >
      </div>
      <div class="nav__logo">
        <span>我的</span>
      </div>
      <a class="nav__right">
        <img
          v-if="isLogin"
          class="fn-logout-icon"
          src="@/assets/frontend/logout.png"
          @click="logout"
        >
      </a>
    </div>
  </client-only>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: 'IndexHeader',
  computed: {
    isLogin() {
      return this.$store.state.member.isLogin
    }
  },
  methods: {
    logout() {
      Dialog.confirm({
        title: '登出',
        message: '是否确定要登出？'
      })
        .then(() => {
          this.$store.dispatch('member/removeLoginInfo')
          window.setTimeout(() => {
            this.$router.push('/m/member')
          }, 1000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.fn-logout-icon {
  width: 22px;
  height: 22px;
  margin-right:10px;
  opacity: 0.8;
}
</style>
