import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'

import CheckboxGroup from 'element-ui/lib/checkbox-group'
import Checkbox from 'element-ui/lib/checkbox'
import DatePicker from 'element-ui/lib/date-picker'
import RadioGroup from 'element-ui/lib/radio-group'
import Radio from 'element-ui/lib/radio'
import Dialog from 'element-ui/lib/dialog'
import Popover from 'element-ui/lib/popover'
import Select from 'element-ui/lib/select'
import Option from 'element-ui/lib/option'
import Input from 'element-ui/lib/input'
import Tabs from 'element-ui/lib/tabs'
import TabPane from 'element-ui/lib/tab-pane'
import Tooltip from 'element-ui/lib/tooltip'
import MessageBox from 'element-ui/lib/message-box'
import Message from 'element-ui/lib/message'
import Pagination from 'element-ui/lib/pagination'
import Loading from 'element-ui/lib/loading'
import Button from 'element-ui/lib/button'
import Image from 'element-ui/lib/image'

Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Radio.name, Radio)
Vue.component(CheckboxGroup.name, CheckboxGroup)
Vue.component(Checkbox.name, Checkbox)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Dialog.name, Dialog)
Vue.component(Popover.name, Popover)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Input.name, Input)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Pagination.name, Pagination)
Vue.component(Button.name, Button)
Vue.component(Image.name, Image)
Vue.use(Loading)
