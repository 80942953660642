export default async({ isDesktop, isMobile, redirect, route, store }) => {
  const { path } = route
  const mobilePath = /^\/m\//.test(path)

  const whiteRegexp = [/^\/transfer\/toolbox\/\w+/]

  if (path === '/m/images' || path === '/m/images/') {
    redirect('/m/images/liuHe')
  }

  if (path === '/images' || path === '/images/') {
    redirect('/images/liuHe')
  }
  if ((!path.startsWith('/m/images') && isMobile) || !path.startsWith('/images') && isDesktop) {
    store.commit('lottery/SET_DIRECT_ENTER_IMAGE', false) // 從 tab 或選單直接進入圖庫，會設為 true，離開頁面時會設為 false
  }
  if (isMobile && !mobilePath) {
    for (let i = 0; i < whiteRegexp.length; i++) {
      if (whiteRegexp[i].test(path)) return
    }

    redirect('/m') // TODO: 看情況要不要導轉到兩個裝置的對應路由
  }
}
