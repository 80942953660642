import Axios from 'axios'
import { hasToken, getToken } from '~/utils/member'
const requestInstance = Axios.create({
  baseURL: process.server ? `http://0.0.0.0:${process.env.PORT}/api` : '/api',
  timeout: 60000 // request timeout
})
requestInstance.defaults.headers.common['X-Site-Id'] = 19
// request interceptor
requestInstance.interceptors.request.use(
  // 請求送出去之前: 可以在這邊加上共用的header 之類的，如token 等等

  (config) => {
    config.headers.common['X-Site-Id'] = process.env.X_SITE_ID || 1
    config.headers.common['X-Sub-Template-Id'] = process.env.X_SUB_TEMPLATE_ID || 1
    config.headers.common['X-Site-Group-Id'] = process.env.X_SITE_GROUP_ID || 1
    let token = ''
    if (hasToken()) {
      token = getToken()
      token && (config.headers.Authorization = token)
    }
    return config
  },

  // do something with request error
  (error) => Promise.reject(error)
)

// response interceptor
requestInstance.interceptors.response.use(
  (response) => {
    const data = response.data ? response.data : {}
    const status = data.status
    let result = null
    const { params = {}} = response.config
    const { interceptorsOptions = {}} = params
    const { deconstruct = true } = interceptorsOptions
    result = deconstruct ? data.data : response
    switch (status) {
      case 1:
        return [result, null]
      default:
        return [null, result]
    }
  },
  (error, config) => {
    // TODO: global error
    // server-side 要倒轉、client-side 好像也要?
    const errorResponse =
    error.response ? error.response : error
    return [null, errorResponse]
  }
)

export default requestInstance
