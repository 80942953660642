import get from 'lodash.get'

export default (context, inject) => {
  const { store } = context

  inject('getLotteryType', async(lotteryCode) => {
    const [lotteryMap] = await store.dispatch('lottery/getLotteryMap')

    const lotteryType = get(lotteryMap[lotteryCode], 'cpType')
    switch (lotteryType) {
      case 'syx5':
        return '11x5' // different!
      case 'k3':
        return 'k3'
      case 'pk10':
        return 'pk10'
      case 'xy28':
        return 'xy28'
      case 'ssc':
        return 'ssc'
      case 'kl10':
        return 'kl10'
      case 'kl8':
        return 'kl8'
      case 'sixhc':
        return 'hk6' // different!
      case 'normal':
        return 'others' // different!
      default:
        console.warn('getLotteryType: 後端的cpType 對應失敗! 將回傳others.', lotteryType)
        return 'others'
    }
  })
}
