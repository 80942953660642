import request from '@/utils/request'
import selfRequest from '@/utils/selfRequest'

const login_web = function(payload) {
  return request({
    url: `/v1/cp168/apis/login`,
    method: 'post',
    data: payload,
    params: {
      interceptorsOptions: { deconstruct: false }
    }
  })
}

const register_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/register',
    method: 'post',
    data: payload,
    params: {
      interceptorsOptions: { deconstruct: false }
    }
  })
}

const findPwdValidate_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/findPassword/validate',
    method: 'get',
    params: {
      ...payload,
      interceptorsOptions: { deconstruct: false }
    }
  })
}

const findPwdNewPwd_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/findPassword/newPassword',
    method: 'put',
    params: {
      interceptorsOptions: { deconstruct: false }
    },
    data: payload
  })
}

const getUserInfo_web = function() {
  return request({
    url: `/v1/cp168/apis/me?call=${new Date().getTime()}`,
    method: 'get'
  })
}

const getBasicInfo_web = function() {
  return request({
    url: `/v1/cp168/apis/me/basicInfo?call=${new Date().getTime()}`,
    method: 'get'
  })
}

const editBasicInfo_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/me/basicInfo',
    method: 'put',
    data: payload
  })
}

const getPredictionReport_web = function(query) {
  return request({
    url: '/v1/cp168/apis/me/predictionReport',
    method: 'get',
    params: query
  })
}

const getScoreHistories_web = function(query) {
  return request({
    url: '/v1/cp168/apis/me/scoreHistories',
    method: 'get',
    params: query
  })
}

const sendFeedback_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/setting/feedback',
    method: 'post',
    data: payload,
    params: {
      interceptorsOptions: { deconstruct: false }
    }
  })
}

const fileUpload_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/file',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: payload
  })
}

const verifyCaptcha = function(query) {
  return selfRequest({
    url: '/verify',
    method: 'get',
    params: query
  })
}

export {
  login_web,
  register_web,
  findPwdValidate_web,
  findPwdNewPwd_web,
  getUserInfo_web,
  getBasicInfo_web,
  editBasicInfo_web,
  getPredictionReport_web,
  getScoreHistories_web,
  sendFeedback_web,
  fileUpload_web,
  verifyCaptcha
}
