import request from '@/utils/request'

// 开奖 api - Sean & Steven

const getDrawInfo_mobile = function(code, query) {
  return request({
    url: `/v1/cp168/wap/draw/info/${code}`,
    method: 'get',
    params: query
  })
}

const getDetailDrawInfo_mobile = function(code) {
  return request({
    url: `/v1/cp168/wap/draw/infoDetail?cpCode=${code}`,
    method: 'get'
  })
}

const getBatchDetailDrawInfo_mobile = function(codes) {
  return request({
    url: `/v1/cp168/wap/draw/infoDetail?cpCode=${codes.join(',')}`,
    method: 'get'
  })
}

const getRuleDetails_mobile = function(code, query) {
  return request({
    url: `/v1/cp168/wap/ruleDetails/${code}`,
    method: 'get',
    params: query
  })
}

const getSiteInfo_mobile = function() {
  return request({
    url: '/v1/cp168/wap/site/info',
    method: 'get'
  })
}

const getCpsList_mobile = function(categoryCode) {
  return request({
    url: `/v1/cp168/wap/cps?categoryCode=${categoryCode}`,
    method: 'get'
  })
}

const getCpLocalPlaceCode_mobile = function() {
  return request({
    url: '/v1/public/param/cp_local_place_code',
    method: 'get'
  })
}

const getBuyPlans_mobile = function(query) {
  return request({
    url: '/v1/cp168/wap/recommend/jihuas',
    method: 'get',
    params: query
  })
}

export {
  getDrawInfo_mobile,
  getDetailDrawInfo_mobile,
  getBatchDetailDrawInfo_mobile,
  getRuleDetails_mobile,
  getSiteInfo_mobile,
  getCpsList_mobile,
  getCpLocalPlaceCode_mobile,
  getBuyPlans_mobile
}
