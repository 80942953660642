<template>
  <div class="nav">
    <a class="nav__left" @click="$router.go(-1)">
      <img src="@/assets/style/img/nav/iconReturn@2x.png" alt="找不到圖片！">
    </a>
    <a class="nav__logo">
      <span>{{ title }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'CommonHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
