<template>
  <div class="layout-main" style="font-size: 16px;">
    <component
      :is="layoutSetting[currentPageIndex].navComponent"
      :title="layoutSetting[currentPageIndex].title"
    />
    <nuxt />
    <!-- <div class="info-content">
      <span class="info-content__text">没有更多数据</span>
    </div> -->
    <component :is="layoutSetting[currentPageIndex].footerComponent" />

  </div>
</template>

<script>
import CommonHeader from '@/components/mobile/layout/CommonHeader'
import WapFooter from '@/components/mobile/layout/WapFooter'
export default {
  name: 'MemberLayout',
  components: {
    CommonHeader,
    WapFooter
  },
  data() {
    return {
      currentPageIndex: '',
      layoutSetting: {
        edit: {
          title: '',
          navComponent: 'CommonHeader',
          footerComponent: ''
        },
        feedback: {
          title: '意见反馈',
          navComponent: 'CommonHeader',
          footerComponent: ''
        },
        forgot: {
          title: '忘记密码',
          navComponent: 'CommonHeader',
          footerComponent: ''
        },
        info: {
          title: '基本资料',
          navComponent: 'CommonHeader',
          footerComponent: ''
        },
        login: {
          title: '登录',
          navComponent: 'CommonHeader',
          footerComponent: ''
        },
        state: {
          title: '免责声明',
          navComponent: 'CommonHeader',
          footerComponent: ''
        },
        setting: {
          title: '设置',
          navComponent: 'CommonHeader',
          footerComponent: ''
        },
        register: {
          title: '注册',
          navComponent: 'CommonHeader',
          footerComponent: ''
        }
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        // 避免導轉到其他路由時進來這個處理函式導致js報錯
        if (/[^\/m\/member\/]/.test(this.$route.path)) return

        if (this.$route.path === '/m/member') return
        else this.currentPageIndex = this.$route.path.replace(/^\/m\/member\//g, '')

        if (this.currentPageIndex === 'edit') {
          this.layoutSetting['edit'].title = this.$route.query.mode === 'pwd' ? '修改密码' : '修改手机号码'
        }
        this.$store.dispatch('member/checkLocalStorage_mobile')
      }
    }
  },
  mounted() {
    this.$store.dispatch('member/checkLocalStorage_mobile')
  },
  created() {
    this.currentPageIndex = this.$route.path.replace(/^\/m\/member\//g, '')
    if (this.currentPageIndex === 'edit') {
      this.layoutSetting['edit'].title = this.$route.query.mode === 'pwd' ? '修改密码' : '修改手机号码'
    }
  },
  head() {
    return {
      style: [
        { type: 'text/css', cssText: require('@/assets/style/css/main.css') }
      ],
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
    }
  }
}
</script>
