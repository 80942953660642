<template>
  <div class="rule-popup" :class="{'is-active': lotteryInfo.popupIsOpen}">
    <div class="popup-mask">
      <div class="popup">
        <div class="popup__header">
          <div class="popup__header__top">
            <div class="header-title">
              <img class="title-icon" src="@/assets/style/img/popup/iconSetUp@2x.png" alt="">
              <p class="title-text">说明</p>
            </div>
            <a class="header-close" @click.prevent="closePopup">
              <img class="close-icon" src="@/assets/style/img/popup/iconPopupCross@2x.png" alt="">
            </a>
          </div>
        </div>
        <div v-if="!lotteryInfo.isPredict" class="popup__body">
          <div v-if="lotteryInfo.type === 'pk10'" class="rule-list">
            <p>1. 单球: 开出的号码</p>
            <p>◎大小: 大于或等于6为大，小于或等于5为小</p>
            <p>◎单双: 为双数叫双，如4、6；号码为单数叫单，如3、5</p>
            <p>2. 冠亚军和: 开奖结果冠军号码与亚军号码的和值</p>
            <p>◎大小: 大于11为大, 小于11为小, 等于11视为和</p>
            <p>◎单双: 能被2整除的数为“双”，反之，为“单”，若总和为11则视为“和”</p>
            <p>3. 1-5龙虎</p>
            <p>冠军vs第十名</p>
            <p>◎龙：冠军号码大于第十名号码视为“龙”中奖</p>
            <p>◎虎：冠军号码小于第十名号码视为“虎”中奖</p>
            <p>亚军vs第九名</p>
            <p>◎龙：亚军号码大于第九名号码视为“龙”中奖</p>
            <p>◎虎：亚军号码小于第九名号码视为“虎”中奖</p>
            <p>季军vs第八名</p>
            <p>◎龙：季军号码大于第八名号码视为“龙”中奖</p>
            <p>◎虎：季军号码小于第八名号码视为“虎”中奖</p>
            <p>第四名vs第七名</p>
            <p>◎龙：第四名号码大于第七名号码视为“龙”中奖</p>
            <p>◎虎：第四名号码小于第七名号码视为“虎”中奖</p>
            <p>第五名vs第六名</p>
            <p>◎龙：第五名号码大于第六名号码视为“龙”中奖</p>
            <p>◎虎：第五名号码小于第六名号码视为“虎”中奖</p>
            <p>4. “前”表示号码开出在前5名，“后”表示号码开出在后5名</p>
            <p>5. 质合</p>
            <p>◎质数: 1、2、3、5、7</p>
            <p>◎合数: 0、4、6、8、9</p>
            <p>6. 012路</p>
            <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
            <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
            <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
          </div>
          <div v-if="lotteryInfo.type === 'k3'" class="rule-list">
            <p>1. 百/十/个位</p>
            <p>◎ 大小 : 1、2、3为小，4、5、6为大</p>
            <p>◎ 单双：1、3、5为单，2、4、6为双</p>
            <p>2. 总和</p>
            <p>◎大小: 三个开奖号码总和值11~17 为大；总和值4~10 为小；若三个号码相同、则为和 (平局)</p>
            <p>◎单双: 三个开奖号码总和5、7、9、11、13、15、17为单；4、6、8、10、12、14、16为双；若三个号码相同、则为和 (平局)</p>
            <p>3. 鱼虾蟹又称鱼虾蟹骰宝, 下注形式跟骰子一样, 采用骰子由鱼, 虾, 蟹, 金钱, 胡芦及鸡代替点数</p>
            <p>一点: 鱼 (红色)</p>
            <p>二点: 虾 (绿色)</p>
            <p>三点: 葫卢 (蓝色)</p>
            <p>四点: 金钱 (蓝色)</p>
            <p>五点: 蟹 (绿色)</p>
            <p>六点: 鸡 (红色)</p>
            <p>4. 质合</p>
            <p>◎质数: 1、2、3、5、7</p>
            <p>◎合数: 0、4、6、8、9</p>
            <p>5. 012路</p>
            <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
            <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
            <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
            <p>6. 号码形态</p>
            <p>◎ 豹子：中奖号码的三个数字都相同。如中奖号码为111、222、333等</p>
            <p>◎ 对子：中奖号码的任意两位数字相同。（不包括豹子）如中奖号码为121、112、211等</p>
            <p>◎ 三不同：不包括豹子、对子、的所有中奖号码，如为136、624等</p>
          </div>
          <div v-if="lotteryInfo.type === '11x5'" class="rule-list">
            <p>1. 单码两面 - 指第一球~第五球</p>
            <p>◎大小：开出的号码大于或等于6为“大”，小于或等于5为“小”，开出11为“和”</p>
            <p>◎单双：能被2整除的数为“双”，反之，为“单”</p>
            <p>2. 总和: 所有开奖号码数字加总值</p>
            <p>◎ 大小: 大于30为“大”；总和值小于30为“小”；若总和值等于30为“和”</p>
            <p>◎单双: 能被2整除的数为“双”，反之，为“单”</p>
            <p>◎尾数大小: 大于或等于5为尾大，小于或等于4为尾小</p>
            <p>3. 龙虎玩法:</p>
            <p>◎龙：第一球开奖号码大于第五球开奖号码</p>
            <p>◎虎：第一球开奖号码小于第五球开奖号码</p>
            <p>4. 前三(万、千、百位), 中三 (千、百、十位), 后三(百、十、个位)</p>
            <p>◎豹子：中奖号码的三个数字都相同 ex 000、111、999</p>
            <p>◎顺子：中奖号码的三个数字都相连，不分顺序 ex 123、901、321、546</p>
            <p>◎对子：中奖号码三位任意两位数字相同（不包括豹子）ex 001，112、696</p>
            <p>◎半顺：中奖号码的三位上任意两位数字相连，不分顺序（不包括顺子、对子) ex 125、540、390、706</p>
            <p>◎杂六：三位开奖号码不包括豹子、对子、顺子、半顺的其他所有中奖号码 ex 157</p>
            <p>5. 质合</p>
            <p>◎质数: 1、2、3、5、7等</p>
            <p>◎合数: 0、4、6、8、9等</p>
            <p>6. 012路</p>
            <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
            <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
            <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
          </div>
          <div v-if="lotteryInfo.type === 'ssc'" class="rule-list">
            <p>1. 单码两面: 指第一球~第五球</p>
            <p>◎大小 : 5、6、7、8、9时为“大”，0、1、2、3、4时为“小”</p>
            <p>◎单双: 1、3、5、7、9时为“单”，0、2、4、6、8时为“双”</p>
            <p>2. 总和: 加总当期开奖结果五个彩球号码</p>
            <p>◎总和大小: 0~22为小、23~45为大</p>
            <p>◎总和单双: 能被2整除的数为“双”，反之，为“单”</p>
            <p>3. 1-5龙虎</p>
            <p>◎龙: 当期开奖结果“万位”号码大于“个位”的中奖号码。</p>
            <p>◎虎: 当期开奖结果“万位”号码小于“个位”的中奖号码。</p>
            <p>◎和: 当期开奖结果“万位”号码等于“个位”的中奖号码。</p>
            <p>4. 前三(万、千、百位), 中三 (千、百、十位), 后三(百、十、个位)</p>
            <p>◎豹子：中奖号码的三个数字都相同 ex 000、111、999</p>
            <p>◎顺子：中奖号码的三个数字都相连，不分顺序 ex 123、901、321、546</p>
            <p>◎对子：中奖号码三位任意两位数字相同（不包括豹子）ex 001，112、696</p>
            <p>◎半顺：中奖号码的三位上任意两位数字相连，不分顺序（不包括顺子、对子) ex 125、540、390、706</p>
            <p>◎杂六：三位开奖号码不包括豹子、对子、顺子、半顺的其他所有中奖号码 ex 157</p>
            <p>5. 质合</p>
            <p>◎质数: 1、2、3、5、7等</p>
            <p>◎合数: 0、4、6、8、9等</p>
            <p>6. 012路</p>
            <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
            <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
            <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
          </div>
          <div v-if="lotteryInfo.type === 'xy28'" class="rule-list">
            <p>1. 总和双面: 取当期开奖号码和值</p>
            <p>◎大小: 数字14-27为大；数字0-13为小</p>
            <p>◎单双:能被2整除的数为“双”，反之，为“单”</p>
            <p>2. 波色: 取当期开奖号码和值</p>
            <p>绿波: 1，4，7，10，16，19，22，25</p>
            <p>蓝波: 2，5，8，11，17，20，23，26</p>
            <p>红波: 3，6，9，12，15，18，21，24</p>
            <p>和：0、13、14、27</p>
            <p>3. 极值: 取当期开奖号码和值</p>
            <p>极小: 0，1，2，3，4，5</p>
            <p>极大: 22，23，24，25，26，27</p>
          </div>
          <div v-if="lotteryInfo.type === 'kl8'" class="rule-list">
            <p>1. 单球:</p>
            <p>◎大小 : 41~80时为“大”, 01~40时为“小”</p>
            <p>◎单双: 能被2整除则为“双”，否则为“单”</p>
            <p>2. 总和: 所有20个开奖数字之和</p>
            <p>◎大、小：大于810则为“大”，小于810则为“小”，如果值为810则为“和”</p>
            <p>◎单、双：能被2整除则为“双，否则为“单”</p>
            <p>3. 奇、偶盘 (单双):</p>
            <p>开出的20个中奖号码中, 如奇数号码数目占多数(超过10个)，则为奇盘(单多)，投注“奇”者中奖;偶数号码占多数(超过10个)，则为偶盘(双多)，投注“偶”者中奖;如果奇数和偶数号码数目相同时(均为 10个)，则为合(单双和)</p>
            <p>4. 上、下盘 (前后)</p>
            <p>号码1至40为上盘号码，41至80为下盘号码。开出的20个开奖号码中：如上盘号码占多数(超过10个)时，此局为上盘(前多);下盘号码占多数时为下盘(后多);上下盘号码在此局开出的数目相同时(各10个数字)，此局为和盘</p>
            <p>5. 五行，即总数五行</p>
            <p>开出的20个号码的总和分在5个段，以金、木、水、火、土命名：金(210～695)、木(696～763)、水(764～855)、火(856～923)和土(924～1410)</p>
          </div>
          <div v-if="lotteryInfo.type === 'kl10'" class="rule-list">
            <p>1. 单球</p>
            <p>◎大小：开出号码大于等于11，为“大”，小于11，为“小”</p>
            <p>◎单双：能被2整除的数为“双”，反之，为“单”</p>
            <p>2. 总和: 所有8个开奖数字之和</p>
            <p>◎大小：处于85~132之间，为“大”，等于84为“和”，小于84为“小”</p>
            <p>◎单双：能被2整除的数为“双”，反之，为“单”</p>
            <p>◎尾数大小: 开出号码的个位大于等于5，为“尾大”，小于或等于4为“尾小”</p>
            <p>3. 龙虎</p>
            <p>• “第一球”的号码大于“第八球”的号码，为【龙】，反之为【虎】</p>
            <p>• “第二球”的号码大于“第七球”的号码，为【龙】，反之为【虎】</p>
            <p>• “第三球”的号码大于“第六球”的号码，为【龙】，反之为【虎】</p>
            <p>• “第四球”的号码大于“第五球”的号码，为【龙】，反之为【虎】</p>
            <p>4. 合数单双: 每个球的十位数加个位数的和, 能被2整除的数为“双”，反之，为“单”</p>
            <p>5. 中发白: 开出号码为</p>
            <p>中: 01、02、03、04、05、06、07</p>
            <p>发: 08、09、10、11、12、13、14</p>
            <p>白: 15、16、17、18、19、20</p>
            <p>6. 东南西北: 开出号码为</p>
            <p>東: 01、05、09、13、17</p>
            <p>南: 02、06、10、14、18</p>
            <p>西: 03、07、11、15、19</p>
            <p>北: 04、08、12、16、20</p>
          </div>
          <div v-if="lotteryInfo.type === 'others'" class="rule-list">
            <p>总和: 所有3个开奖数字之和</p>
            <p>◎大小：14~27 为“大”，0~13 为“小”</p>
            <p>◎单双：能被2整除的数为“双”，反之，为“单”</p>
          </div>
          <div v-if="lotteryInfo.type === 'hk6'" class="rule-list">
            <p>1. 总和: 所有七个开奖号码加总</p>
            <p>◎大小: 大于或等于175，小于或等于174</p>
            <p>◎单双: 能被2整除的数为“双”，反之，为“单”</p>
            <p>2. 七色波：以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。</p>
            <p>以下3种结果视为和局</p>
            <p>6个正码开出3蓝3绿，而特别码是1.5红</p>
            <p>6个正码开出3蓝3红，而特别码是1.5绿</p>
            <p>6个正码开出3绿3红，而特别码是1.5蓝</p>
            <p>3. 特码</p>
            <p>◎大小: 01~24为小，25~48为大，49则为和</p>
            <p>◎单双: 能被2整除的数为“双”，反之，为“单”, 49则视为“和”</p>
            <p>4. 特码合数:  特码的个位加上十位之和</p>
            <p>◎大小: 大于或等于7为大，和数小于或等于6为小, 特码为49时视为和</p>
            <p>◎单双: 能被2整除的数为双，反之，为单，特码为49时视为和</p>
            <p>5. 特码尾数大小: 5尾~9尾为大，0尾~4尾为小，特码为49时视为和</p>
          </div>
        </div>
        <div v-if="lotteryInfo.isPredict" class="popup__body">
          <div v-if="lotteryInfo.predictType === 'shjh'">
            <p>杀号: 专家认为当前不可能开出的号码</p>
            <p>专家: 共5位专家每期提供一个他们认为在当期不可能出现的号码</p>
          </div>
        </div>
        <div class="popup__footer">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RulePopup',
  props: {
    lotteryInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    closePopup() {
      this.lotteryInfo.popupIsOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .rule-popup {
    display: none;
  }
  .is-active {
    display: block;
  }
  .rule-popup .popup-mask .popup__body .rule-list {
    /* 處理 double scroll 問題 */
    max-height: 50vh;
  }
  .popup__header {
    display: flex;
    flex-direction: column;
    &__top {
      display: flex;
      justify-content: space-between;
      padding: 16px;
    }
  }
</style>
