import request from '@/utils/request'

export const rankingWins_mobile = function(query) {
  return request({
    url: '/v1/cp168/wap/rankingWins',
    method: 'get',
    params: query
  })
}

export const rankingProfits_mobile = function(query) {
  return request({
    url: '/v1/cp168/wap/rankingProfits',
    method: 'get',
    params: query
  })
}

export const latestPredictions_mobile = function(query) {
  return request({
    url: '/v1/cp168/wap/latestPredictions',
    method: 'get',
    params: query
  })
}

export const getPredictionStats_mobile = function(userID) {
  return request({
    url: `/v1/cp168/wap/predictionStats/${userID}`,
    method: 'get'
  })
}

export const predictionsHistories_mobile = function(userID, query) {
  return request({
    url: `/v1/cp168/wap/predictionsHistories/${userID}`,
    method: 'get',
    params: query
  })
}

export const getSubList_mobile = function(query) {
  return request({
    url: `/v1/cp168/wap/subscribedUsers`,
    method: 'get',
    params: query
  })
}

export const subscribe_mobile = function(payload) {
  return request({
    url: '/v1/cp168/wap/subscribedUsers',
    method: 'post',
    data: payload
  })
}

export const cancelSub_mobile = function(userID) {
  return request({
    url: `/v1/cp168/wap/subscribedUsers/${userID}`,
    method: 'delete'
  })
}

export const renewToken_mobile = function() {
  return request({
    url: '/v1/cp168/wap/renewToken',
    method: 'post'
  })
}

export const signIn_mobile = function() {
  return request({
    url: '/v1/cp168/wap/me/signin',
    method: 'post'
  })
}

export const postPredictions_mobile = function(payload) {
  return request({
    url: '/v1/cp168/wap/predictions',
    method: 'post',
    data: payload
  })
}

export const checkPrediction_mobile = function(query) {
  return request({
    url: `/v1/cp168/wap/checkPrediction`,
    method: 'get',
    params: query
  })
}
