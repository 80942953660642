<template>

  <nuxt />

</template>

<script>
export default {
  head() {
    return {
      link: [
        { rel: 'stylesheet', href: '/desktop/css/main.css' }
      ],
      title: `${this.$store.state.site.siteInfo.siteName}`,
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
    }
  }
}
</script>
