import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b7851ea = () => interopDefault(import('../pages/changltx/index.vue' /* webpackChunkName: "pages/changltx/index" */))
const _efc6c6dc = () => interopDefault(import('../pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _1a43aea8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9973709a = () => interopDefault(import('../pages/m/index.vue' /* webpackChunkName: "pages/m/index" */))
const _f87d3b32 = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _6e1e3faa = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _4d1ca5f8 = () => interopDefault(import('../pages/member/integral.vue' /* webpackChunkName: "pages/member/integral" */))
const _32fca7d2 = () => interopDefault(import('../pages/member/jingTsai.vue' /* webpackChunkName: "pages/member/jingTsai" */))
const _2d24f27b = () => interopDefault(import('../pages/transfer/index.vue' /* webpackChunkName: "pages/transfer/index" */))
const _63e98d6b = () => interopDefault(import('../pages/images/liuHe.vue' /* webpackChunkName: "pages/images/liuHe" */))
const _2ca998d8 = () => interopDefault(import('../pages/images/pk10.vue' /* webpackChunkName: "pages/images/pk10" */))
const _293e259d = () => interopDefault(import('../pages/lottery/trend/index.vue' /* webpackChunkName: "pages/lottery/trend/index" */))
const _2c33770e = () => interopDefault(import('../pages/lotterybet/lotteryhome.vue' /* webpackChunkName: "pages/lotterybet/lotteryhome" */))
const _2d511ddc = () => interopDefault(import('../pages/lotterybet/lotteryhome/_lottery_code/mylotterybet.vue' /* webpackChunkName: "pages/lotterybet/lotteryhome/_lottery_code/mylotterybet" */))
const _6db0e687 = () => interopDefault(import('../pages/lotterybet/lotteryhome/_lottery_code/mysubscription.vue' /* webpackChunkName: "pages/lotterybet/lotteryhome/_lottery_code/mysubscription" */))
const _65965c7a = () => interopDefault(import('../pages/lotterybet/lotteryhome/_lottery_code/newlotterybet.vue' /* webpackChunkName: "pages/lotterybet/lotteryhome/_lottery_code/newlotterybet" */))
const _14c8882c = () => interopDefault(import('../pages/lotterybet/lotteryhome/_lottery_code/rank.vue' /* webpackChunkName: "pages/lotterybet/lotteryhome/_lottery_code/rank" */))
const _48abdf82 = () => interopDefault(import('../pages/lotterybet/lotteryhome/_lottery_code/userInfo/_id.vue' /* webpackChunkName: "pages/lotterybet/lotteryhome/_lottery_code/userInfo/_id" */))
const _ed2cd2a6 = () => interopDefault(import('../pages/m/changltx/index.vue' /* webpackChunkName: "pages/m/changltx/index" */))
const _15937fcc = () => interopDefault(import('../pages/m/member/index.vue' /* webpackChunkName: "pages/m/member/index" */))
const _0b5ecd8d = () => interopDefault(import('../pages/m/images/liuHe.vue' /* webpackChunkName: "pages/m/images/liuHe" */))
const _185c6732 = () => interopDefault(import('../pages/m/images/pk10.vue' /* webpackChunkName: "pages/m/images/pk10" */))
const _8f0f16a0 = () => interopDefault(import('../pages/m/lotterybet/lotteryhome.vue' /* webpackChunkName: "pages/m/lotterybet/lotteryhome" */))
const _3054001a = () => interopDefault(import('../pages/m/lotterybet/lotteryhome/_lottery/mylotterybet.vue' /* webpackChunkName: "pages/m/lotterybet/lotteryhome/_lottery/mylotterybet" */))
const _307458e2 = () => interopDefault(import('../pages/m/lotterybet/lotteryhome/_lottery/mysubscription.vue' /* webpackChunkName: "pages/m/lotterybet/lotteryhome/_lottery/mysubscription" */))
const _03a8a31c = () => interopDefault(import('../pages/m/lotterybet/lotteryhome/_lottery/newlotterybet.vue' /* webpackChunkName: "pages/m/lotterybet/lotteryhome/_lottery/newlotterybet" */))
const _547bd2f2 = () => interopDefault(import('../pages/m/lotterybet/lotteryhome/_lottery/rank.vue' /* webpackChunkName: "pages/m/lotterybet/lotteryhome/_lottery/rank" */))
const _e0af22ec = () => interopDefault(import('../pages/m/lotterybet/lotteryhome/_lottery/userInfo/_id.vue' /* webpackChunkName: "pages/m/lotterybet/lotteryhome/_lottery/userInfo/_id" */))
const _02d90c00 = () => interopDefault(import('../pages/m/member/edit.vue' /* webpackChunkName: "pages/m/member/edit" */))
const _5ffe62fb = () => interopDefault(import('../pages/m/member/feedback.vue' /* webpackChunkName: "pages/m/member/feedback" */))
const _60bf9b19 = () => interopDefault(import('../pages/m/member/forgot.vue' /* webpackChunkName: "pages/m/member/forgot" */))
const _ac9d02b8 = () => interopDefault(import('../pages/m/member/info.vue' /* webpackChunkName: "pages/m/member/info" */))
const _1f03e1a2 = () => interopDefault(import('../pages/m/member/integral.vue' /* webpackChunkName: "pages/m/member/integral" */))
const _2c13e0b5 = () => interopDefault(import('../pages/m/member/jingTsai.vue' /* webpackChunkName: "pages/m/member/jingTsai" */))
const _4189ba3a = () => interopDefault(import('../pages/m/member/login.vue' /* webpackChunkName: "pages/m/member/login" */))
const _6111e719 = () => interopDefault(import('../pages/m/member/register.vue' /* webpackChunkName: "pages/m/member/register" */))
const _336ab46c = () => interopDefault(import('../pages/m/member/setting.vue' /* webpackChunkName: "pages/m/member/setting" */))
const _6d3db7ea = () => interopDefault(import('../pages/m/member/state.vue' /* webpackChunkName: "pages/m/member/state" */))
const _452b50f6 = () => interopDefault(import('../pages/m/lottery/group/_group_id.vue' /* webpackChunkName: "pages/m/lottery/group/_group_id" */))
const _15f2d86b = () => interopDefault(import('../pages/m/lottery/trend/_lottery_code.vue' /* webpackChunkName: "pages/m/lottery/trend/_lottery_code" */))
const _0f6edec4 = () => interopDefault(import('../pages/lottery/history/_lottery_code.vue' /* webpackChunkName: "pages/lottery/history/_lottery_code" */))
const _6d9ebe66 = () => interopDefault(import('../pages/lottery/trend/_lottery_code.vue' /* webpackChunkName: "pages/lottery/trend/_lottery_code" */))
const _9261befa = () => interopDefault(import('../pages/m/buyplan/_lottery_code.vue' /* webpackChunkName: "pages/m/buyplan/_lottery_code" */))
const _0a18ade2 = () => interopDefault(import('../pages/m/expert/_lottery_code/index.vue' /* webpackChunkName: "pages/m/expert/_lottery_code/index" */))
const _375491b9 = () => interopDefault(import('../pages/m/lmTj/_lottery_code.vue' /* webpackChunkName: "pages/m/lmTj/_lottery_code" */))
const _034e8556 = () => interopDefault(import('../pages/m/shJh/_lottery_code.vue' /* webpackChunkName: "pages/m/shJh/_lottery_code" */))
const _3e75b099 = () => interopDefault(import('../pages/transfer/toolbox/_code.vue' /* webpackChunkName: "pages/transfer/toolbox/_code" */))
const _02affd26 = () => interopDefault(import('../pages/m/expert/_lottery_code/_detail.vue' /* webpackChunkName: "pages/m/expert/_lottery_code/_detail" */))
const _06c94b36 = () => interopDefault(import('../pages/buyplan/_lottery_code.vue' /* webpackChunkName: "pages/buyplan/_lottery_code" */))
const _3a8a4c6d = () => interopDefault(import('../pages/expert/_lottery_code/index.vue' /* webpackChunkName: "pages/expert/_lottery_code/index" */))
const _263b7317 = () => interopDefault(import('../pages/lmTj/_lottery_code.vue' /* webpackChunkName: "pages/lmTj/_lottery_code" */))
const _2580c29a = () => interopDefault(import('../pages/shJh/_lottery_code.vue' /* webpackChunkName: "pages/shJh/_lottery_code" */))
const _99b9756a = () => interopDefault(import('../pages/expert/_lottery_code/_detail.vue' /* webpackChunkName: "pages/expert/_lottery_code/_detail" */))
const _29368595 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/changltx",
    component: _2b7851ea,
    name: "changltx"
  }, {
    path: "/feedback",
    component: _efc6c6dc,
    name: "feedback"
  }, {
    path: "/login",
    component: _1a43aea8,
    name: "login"
  }, {
    path: "/m",
    component: _9973709a,
    name: "m"
  }, {
    path: "/member",
    component: _f87d3b32,
    children: [{
      path: "",
      component: _6e1e3faa,
      name: "member"
    }, {
      path: "integral",
      component: _4d1ca5f8,
      name: "member-integral"
    }, {
      path: "jingTsai",
      component: _32fca7d2,
      name: "member-jingTsai"
    }]
  }, {
    path: "/transfer",
    component: _2d24f27b,
    name: "transfer"
  }, {
    path: "/images/liuHe",
    component: _63e98d6b,
    name: "images-liuHe"
  }, {
    path: "/images/pk10",
    component: _2ca998d8,
    name: "images-pk10"
  }, {
    path: "/lottery/trend",
    component: _293e259d,
    name: "lottery-trend"
  }, {
    path: "/lotterybet/lotteryhome",
    component: _2c33770e,
    name: "lotterybet-lotteryhome",
    children: [{
      path: ":lottery_code?/mylotterybet",
      component: _2d511ddc,
      name: "lotterybet-lotteryhome-lottery_code-mylotterybet"
    }, {
      path: ":lottery_code?/mysubscription",
      component: _6db0e687,
      name: "lotterybet-lotteryhome-lottery_code-mysubscription"
    }, {
      path: ":lottery_code?/newlotterybet",
      component: _65965c7a,
      name: "lotterybet-lotteryhome-lottery_code-newlotterybet"
    }, {
      path: ":lottery_code?/rank",
      component: _14c8882c,
      name: "lotterybet-lotteryhome-lottery_code-rank"
    }, {
      path: ":lottery_code?/userInfo/:id?",
      component: _48abdf82,
      name: "lotterybet-lotteryhome-lottery_code-userInfo-id"
    }]
  }, {
    path: "/m/changltx",
    component: _ed2cd2a6,
    name: "m-changltx"
  }, {
    path: "/m/member",
    component: _15937fcc,
    name: "m-member"
  }, {
    path: "/m/images/liuHe",
    component: _0b5ecd8d,
    name: "m-images-liuHe"
  }, {
    path: "/m/images/pk10",
    component: _185c6732,
    name: "m-images-pk10"
  }, {
    path: "/m/lotterybet/lotteryhome",
    component: _8f0f16a0,
    name: "m-lotterybet-lotteryhome",
    children: [{
      path: ":lottery?/mylotterybet",
      component: _3054001a,
      name: "m-lotterybet-lotteryhome-lottery-mylotterybet"
    }, {
      path: ":lottery?/mysubscription",
      component: _307458e2,
      name: "m-lotterybet-lotteryhome-lottery-mysubscription"
    }, {
      path: ":lottery?/newlotterybet",
      component: _03a8a31c,
      name: "m-lotterybet-lotteryhome-lottery-newlotterybet"
    }, {
      path: ":lottery?/rank",
      component: _547bd2f2,
      name: "m-lotterybet-lotteryhome-lottery-rank"
    }, {
      path: ":lottery?/userInfo/:id?",
      component: _e0af22ec,
      name: "m-lotterybet-lotteryhome-lottery-userInfo-id"
    }]
  }, {
    path: "/m/member/edit",
    component: _02d90c00,
    name: "m-member-edit"
  }, {
    path: "/m/member/feedback",
    component: _5ffe62fb,
    name: "m-member-feedback"
  }, {
    path: "/m/member/forgot",
    component: _60bf9b19,
    name: "m-member-forgot"
  }, {
    path: "/m/member/info",
    component: _ac9d02b8,
    name: "m-member-info"
  }, {
    path: "/m/member/integral",
    component: _1f03e1a2,
    name: "m-member-integral"
  }, {
    path: "/m/member/jingTsai",
    component: _2c13e0b5,
    name: "m-member-jingTsai"
  }, {
    path: "/m/member/login",
    component: _4189ba3a,
    name: "m-member-login"
  }, {
    path: "/m/member/register",
    component: _6111e719,
    name: "m-member-register"
  }, {
    path: "/m/member/setting",
    component: _336ab46c,
    name: "m-member-setting"
  }, {
    path: "/m/member/state",
    component: _6d3db7ea,
    name: "m-member-state"
  }, {
    path: "/m/lottery/group/:group_id?",
    component: _452b50f6,
    name: "m-lottery-group-group_id"
  }, {
    path: "/m/lottery/trend/:lottery_code?",
    component: _15f2d86b,
    name: "m-lottery-trend-lottery_code"
  }, {
    path: "/lottery/history/:lottery_code?",
    component: _0f6edec4,
    name: "lottery-history-lottery_code"
  }, {
    path: "/lottery/trend/:lottery_code",
    component: _6d9ebe66,
    name: "lottery-trend-lottery_code"
  }, {
    path: "/m/buyplan/:lottery_code?",
    component: _9261befa,
    name: "m-buyplan-lottery_code"
  }, {
    path: "/m/expert/:lottery_code?",
    component: _0a18ade2,
    name: "m-expert-lottery_code"
  }, {
    path: "/m/lmTj/:lottery_code?",
    component: _375491b9,
    name: "m-lmTj-lottery_code"
  }, {
    path: "/m/shJh/:lottery_code?",
    component: _034e8556,
    name: "m-shJh-lottery_code"
  }, {
    path: "/transfer/toolbox/:code?",
    component: _3e75b099,
    name: "transfer-toolbox-code"
  }, {
    path: "/m/expert/:lottery_code?/:detail",
    component: _02affd26,
    name: "m-expert-lottery_code-detail"
  }, {
    path: "/buyplan/:lottery_code?",
    component: _06c94b36,
    name: "buyplan-lottery_code"
  }, {
    path: "/expert/:lottery_code?",
    component: _3a8a4c6d,
    name: "expert-lottery_code"
  }, {
    path: "/lmTj/:lottery_code?",
    component: _263b7317,
    name: "lmTj-lottery_code"
  }, {
    path: "/shJh/:lottery_code?",
    component: _2580c29a,
    name: "shJh-lottery_code"
  }, {
    path: "/expert/:lottery_code?/:detail",
    component: _99b9756a,
    name: "expert-lottery_code-detail"
  }, {
    path: "/",
    component: _29368595,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
