<template>
  <div class="container">
    <div v-if="error.statusCode === 404" class="wrap">
      <NoData />
    </div>
    <div v-else class="wrap">
      <NoData />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/web/common/NoData'
export default {
  components: {
    NoData
  },
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  head() {
    return {
      title: `${this.$store.state.site.siteInfo.siteName}`,
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
    }
  }
}
</script>
