const ruleCodeMapping = Object.assign({
  biggest_lost: '最大遗漏',
  cold: '冷',
  danshuang_dan: '单',
  danshuang_he: '和',
  danshuang_shuang: '双',
  daxiao_da: '大',
  daxiao_he: '和',
  daxiao_xiao: '小',
  mantissa_daxiao_da: '大',
  mantissa_daxiao_he: '和',
  mantissa_daxiao_xiao: '小',
  dongWu_jiaChin: '家禽',
  dongWu_yehShou: '野兽',
  fangSiang_bei: '北',
  fangSiang_dong: '东',
  fangSiang_nan: '南',
  fangSiang_si: '西',
  haiSian_huLu: '葫芦',
  haiSian_ji: '鸡',
  haiSian_jinChian: '金钱',
  haiSian_sia: '虾',
  haiSian_sieh: '蟹',
  haiSian_yu: '鱼',
  he: '和',
  hot: '热',
  jhihHe_he: '合',
  jhihHe_jhih: '质',
  jiZhi_he: '和',
  jiZhi_jiDa: '极大',
  jiZhi_jiXiao: '极小',
  longHu_he: '和',
  longHu_hu: '虎',
  longHu_long: '龙',
  maJiang_bai: '白',
  maJiang_fa: '发',
  maJiang_jhong: '中',
  nanNyu_nan: '男',
  nanNyu_nyu: '女',
  now_lost: '现在遗漏',
  puke_banShun: '半顺',
  puke_baoZih: '豹子',
  puke_dueiZih: '对子',
  puke_shunZih: '顺子',
  puke_zaLiou: '杂六',
  puke_sanBuTong: '三不同',
  shengXiao_gou: '狗',
  shengXiao_hou: '猴',
  shengXiao_hu: '虎',
  shengXiao_jhu: '猪',
  shengXiao_ji: '鸡',
  shengXiao_long: '龙',
  shengXiao_ma: '马',
  shengXiao_niou: '牛',
  shengXiao_she: '蛇',
  shengXiao_shu: '鼠',
  shengXiao_tu: '兔',
  shengXiao_yang: '羊',
  sihJi_chiou: '秋',
  sihJi_chun: '春',
  sihJi_dong: '冬',
  sihJi_sia: '夏',
  sihYi_chi: '棋',
  sihYi_chin: '琴',
  sihYi_hua: '画',
  sihYi_shu: '书',
  tianDi_di: '地',
  tianDi_tian: '天',
  warm: '温',
  wuXing_huo: '火',
  wuXing_jin: '金',
  wuXing_mu: '木',
  wuXing_shuei: '水',
  wuXing_tu: '土',
  yanse_he: '和',
  yanse_hong: '红',
  yanse_lan: '蓝',
  yanse_lyu: '绿',
  zuSyuan_baoZih: '豹子',
  zuSyuan_zuLiou: '组六',
  zuSyuan_zuSan: '组三'
}, {
  shengPingJiang_jiang: '降',
  shengPingJiang_ping: '平',
  shengPingJiang_sheng: '升'
}, {
  lingyierlu_num0: '0',
  lingyierlu_num1: '1',
  lingyierlu_num2: '2'
},
// num1, num2, num3...
(new Array(50).fill().reduce((map, nothing, index) => Object.assign(map, { [`num${index}`]: `${index}` }), {})),
// hm1_num1, hm1_num2, hm2_num1, hm2_num2...
(new Array(12).fill().reduce((map, nothing, hmIndex) => {
  (new Array(12)).fill().forEach((nothing, numIndex) => {
    map[`hm${hmIndex}_num${numIndex}`] = `${numIndex}`
  })
  return map
}, {})),
// for DaxioZouShih k3 number_num1,number_num2...
(new Array(6).fill().reduce((map, nothing, index) => Object.assign(map, { [`number_num${index + 1}`]: `${index + 1}` }), {})),
(new Array(8).fill().reduce((map, nothing, index) => Object.assign(map, { [`hm${index + 1}_daxiao_da`]: `大` }), {})),
(new Array(8).fill().reduce((map, nothing, index) => Object.assign(map, { [`hm${index + 1}_daxiao_xiao`]: `小` }), {})),
(new Array(4).fill().reduce((map, nothing, index) => Object.assign(map, { [`hmDaXiaoDaCount_count${index}`]: index }), {})),
(new Array(4).fill().reduce((map, nothing, index) => Object.assign(map, { [`hmDaXiaoXiaoCount_count${index}`]: index }), {})),
{
  hmDaXiaoRatio_3to0: '3:0',
  hmDaXiaoRatio_2to1: '2:1',
  hmDaXiaoRatio_1to2: '1:2',
  hmDaXiaoRatio_0to3: '0:3'
},
// kl10
{
  hmDaXiaoRatio_0to8: '0:8',
  hmDaXiaoRatio_1to7: '1:7',
  hmDaXiaoRatio_2to6: '2:6',
  hmDaXiaoRatio_3to5: '3:5',
  hmDaXiaoRatio_4to4: '4:4',
  hmDaXiaoRatio_5to3: '5:3',
  hmDaXiaoRatio_6to2: '6:2',
  hmDaXiaoRatio_7to1: '7:1',
  hmDaXiaoRatio_8to0: '8:0'
},
// DaxioZouShih end
// for JiOuZouShih
(new Array(6).fill().reduce((map, nothing, index) => Object.assign(map, { [`number_num${index + 1}`]: `${index + 1}` }), {})),
(new Array(8).fill().reduce((map, nothing, index) => Object.assign(map, { [`hm${index + 1}_danshuang_shuang`]: `偶` }), {})),
(new Array(8).fill().reduce((map, nothing, index) => Object.assign(map, { [`hm${index + 1}_danshuang_dan`]: `奇` }), {})),
(new Array(4).fill().reduce((map, nothing, index) => Object.assign(map, { [`hmDanShuangShuangCount_count${index}`]: index }), {})),
(new Array(4).fill().reduce((map, nothing, index) => Object.assign(map, { [`hmDanShuangDanCount_count${index}`]: index }), {})),
{
  hmDanShuangRatio_0to8: '0:8',
  hmDanShuangRatio_1to7: '1:7',
  hmDanShuangRatio_2to6: '2:6',
  hmDanShuangRatio_3to5: '3:5',
  hmDanShuangRatio_4to4: '4:4',
  hmDanShuangRatio_5to3: '5:3',
  hmDanShuangRatio_6to2: '6:2',
  hmDanShuangRatio_7to1: '7:1',
  hmDanShuangRatio_8to0: '8:0',
  hmDanShuangRatio_3to0: '3:0',
  hmDanShuangRatio_2to1: '2:1',
  hmDanShuangRatio_1to2: '1:2',
  hmDanShuangRatio_0to3: '0:3'
},
// JiOuZouShih end
// number_num1, number_num2, number_num3...
(new Array(21).fill().reduce((map, nothing, index) => Object.assign(map, { [`number_num${index}`]: `${index}` }), {})),
// all_num1, all_num2, all_num3...
(new Array(50).fill().reduce((map, nothing, index) => Object.assign(map, { [`all_num${index}`]: `${index}` }), {})),
// sumguanya_num1, sumguanya_num2, sumguanya_num3...
(new Array(20).fill().reduce((map, nothing, index) => Object.assign(map, { [`sumguanya_num${index}`]: `${index}` }), {})),
{
  all_puke_baoZih: '豹子',
  all_puke_dueiZih: '对子',
  all_puke_sanBuTong: '三不同'
},
{
  all_daxiao_da: '大',
  all_daxiao_xiao: '小',
  all_daxiao_he: '和',
  all_danshuang_dan: '单',
  all_danshuang_shuang: '双',
  all_danshuang_he: '和',
  allmantissa_daxiao_da: '尾大',
  allmantissa_daxiao_xiao: '尾小',
  allmantissa_danshuang_dan: '尾单',
  allmantissa_danshuang_shuang: '尾双'
},
{
  all_longHu_long: '龙',
  all_longHu_hu: '虎',
  all_longHu_he: '和'
},
// for XingTaiZouShih
{
  group1_puke_baoZih: '豹子',
  group1_puke_shunZih: '顺子',
  group1_puke_dueiZih: '对子',
  group1_puke_banShun: '半顺',
  group1_puke_zaLiou: '杂六',
  group2_puke_baoZih: '豹子',
  group2_puke_shunZih: '顺子',
  group2_puke_dueiZih: '对子',
  group2_puke_banShun: '半顺',
  group2_puke_zaLiou: '杂六',
  group3_puke_baoZih: '豹子',
  group3_puke_shunZih: '顺子',
  group3_puke_dueiZih: '对子',
  group3_puke_banShun: '半顺',
  group3_puke_zaLiou: '杂六',
  group1_zuSyuan_zuSan: '组三',
  group1_zuSyuan_zuLiou: '组六',
  group1_zuSyuan_baoZih: '豹子',
  group2_zuSyuan_zuSan: '组三',
  group2_zuSyuan_zuLiou: '组六',
  group2_zuSyuan_baoZih: '豹子',
  group3_zuSyuan_zuSan: '组三',
  group3_zuSyuan_zuLiou: '组六',
  group3_zuSyuan_baoZih: '豹子'
},
{
  appearCount: '出现次数',
  avgMissing: '平均遗漏',
  now: '当前遗漏',
  biggestContinue: '最大连出',
  biggestMissing: '最大遗漏'
})

// 彩種類型名稱對應
const lotteryTypeNameMapping = {
  '11x5': '11选5',
  k3: '快3',
  kl8: '快乐8',
  pk10: 'PK10',
  xy28: '幸运28',
  others: '其他',
  ssc: '时时彩',
  kl10: '快乐10',
  hk6: '六合彩'
}

const presentLabelOptions = [{
  name: '遗漏',
  val: 'presentMissing'
}, {
  name: '折线',
  val: 'presentLine'
}, {
  name: '遗漏分层',
  val: 'presentLineMissingLayer'
}, {
  name: '分隔线',
  val: 'presentLineDivided'
}]

const countRangeOptions = [{
  name: '近30期',
  val: '30'
}, {
  name: '近60期',
  val: '60'
}, {
  name: '近90期',
  val: '90'
}]

export {
  ruleCodeMapping,
  lotteryTypeNameMapping,
  presentLabelOptions,
  countRangeOptions
}
