import request from '@/utils/request'

const getDwhLotteryList = function() {
  return request({
    url: '/v1/public/cp',
    method: 'get'
  })
}

const getCpTypeCode = function() {
  return request({
    url: '/v1/public/param/cp_type_code',
    method: 'get'
  })
}

const getCpPlaceCode = function() {
  return request({
    url: '/v1/public/param/cp_place_code',
    method: 'get'
  })
}

const getSEO = function(params = {}) {
  return request({
    url: '/v1/apis/seo/page',
    params
  })
}

export {
  getDwhLotteryList,
  getCpTypeCode,
  getCpPlaceCode,
  getSEO
}
