<template>
  <div v-if="lotteryCode !== ''">
    <iframe
      height="568"
      width="880"
      scrolling="no"
      :src="videoSrc"
      frameborder="0"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'PopupVideo',
  props: {
    lotteryCode: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      videoLotteryType: '',
      videoSrc: ''
    }
  },
  computed: {
    videoLotteryCode() {
      return this.lotteryCode
    }
  },
  watch: {
    lotteryCode: {
      immediate: true,
      async handler(lotteryCode) {
        const lotteryType = await this.$getLotteryType(lotteryCode)
        const videoLotteryType = this.getVideoLotteryType(lotteryCode, lotteryType)

        if (!videoLotteryType) {
          console.warn('videoLotteryType: 彩種對影影片類型失敗!', lotteryCode, lotteryType)
          return
        }

        const videoLotteryCode = this.videoLotteryCode
        this.videoSrc = `/lottery-animation/${videoLotteryType}_video/index.html?lotteryCode=${videoLotteryCode}`
      }
    }
  },
  methods: {
    getVideoLotteryType(lotteryCode, lotteryType) {
      switch (lotteryCode) {
        case 'fc3d':
        case 'fcssq':
        case 'pl3':
        case 'shssc':
          return 'fc3d'
        case 'cqxync':
          return 'cqnc'
        case 'twbingo':
          return 'twbg'
        case 'malxyft':
          return 'ft'
      }
      switch (lotteryType) {
        case '11x5':
        case 'k3':
        case 'pk10':
        case 'ssc':
          return lotteryType
        case 'hk6':
          return 'lhc'
        case 'kl10':
          return 'klsf'
        case 'xy28':
          return 'pcEgg'
        case 'kl8': // 暫無
          return 'twbg'
      }

      // 以下沒有直播
      // pl5: 排列5
      // qlc: 七乐彩
      // qxc: 七星彩
      // cjdlt: 超级大乐透
      return ''
    }
  }
}
</script>
