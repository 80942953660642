<template>
  <nuxt style="font-size: 16px;" />
</template>

<script>
export default {
  name: 'MemberData',
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.$store.dispatch('member/checkLocalStorage_mobile')
      }
    }
  },
  mounted() {
    this.$store.dispatch('member/checkLocalStorage_mobile')
  },
  head() {
    return {
      style: [
        { type: 'text/css', cssText: require('@/assets/style/css/main.css') }
      ],
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
    }
  }
}
</script>
