<template>
  <div class="layout-tabbar">
    <div class="tabbar">
      <div
        v-for="tab in footerTabbar"
        :key="`168-footer-tabbar-${tab.label}`"
        :class="{'tabbar__item--active': activeTab(tab).class}"
        class="tabbar__item"
        @click="() => handleClick(tab)"
      >
        <div class="tabbar__item__icon">
          <img
            :src="activeTab(tab).src"
            alt="找不到图片！"
          >

          <div v-show="isShowSubItem.pk10" class="subitem__wrapper">
            <div v-for="subitem in tab.children" :key="`tabbar-subitem-${subitem.label}`" class="tabbar__subitem" @click.stop="() => handleSubItemClick(subitem)">
              <span>{{ subitem.label }}</span>
            </div>
          </div>

        </div>
        <span>{{ tab.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WapFooter',
  data() {
    return {
      isShowSubItem: {
        pk10: false
      },
      footerTabbar: [
        {
          href: '/m',
          iconSrc: require('@/assets/style/img/tabbar/icon-img1@2x.png'),
          iconActiveSrc: require('@/assets/style/img/tabbar/icon-img1-Active@2x.png'),
          label: '首页',
          children: [],
          subType: ''
        },
        {
          href: '/m/lottery/group/hotLottery',
          iconSrc: require('@/assets/style/img/tabbar/iconLottery@2x.png'),
          iconActiveSrc: require('@/assets/style/img/tabbar/iconLottery-Active@2x.png'),
          label: '彩种',
          children: [],
          subType: ''
        },
        {
          href: '/m/images',
          iconSrc: require('@/assets/style/img/tabbar/icon-img@2x.png'),
          iconActiveSrc: require('@/assets/style/img/tabbar/icon-img-Active@2x.png'),
          label: '图库',
          children: [{
            href: '/m/images/liuHe',
            label: '六合'
          }, {
            href: '/m/images/pk10',
            label: 'PK拾'
          }],
          subType: 'pk10'
        },
        {
          href: '/m/lotterybet/lotteryhome/jspk10/rank',
          iconSrc: require('@/assets/style/img/tabbar/iconBuy@2x.png'),
          iconActiveSrc: require('@/assets/style/img/tabbar/iconBuy-Active@2x.png'),
          label: '竞猜',
          children: [],
          subType: ''
        },
        {
          href: '/m/member',
          iconSrc: require('@/assets/style/img/tabbar/iconCustomerService@2x.png'),
          iconActiveSrc: require('@/assets/style/img/tabbar/iconCustomerService-Active@2x.png'),
          label: '我的',
          children: [],
          subType: ''
        }
      ]
    }
  },
  methods: {
    activeTab(tab) {
      const routePath = this.$route.path
      switch (tab.href) {
        case '/m/lottery/group/hotLottery':
          if (/^\/m\/lottery\/group/.test(routePath)) {
            return {
              class: true,
              src: tab.iconActiveSrc
            }
          } else {
            return {
              class: false,
              src: tab.iconSrc
            }
          }
        case '/m/member':
          if (/^\/m\/member/.test(routePath)) {
            return {
              class: true,
              src: tab.iconActiveSrc
            }
          } else {
            return {
              class: false,
              src: tab.iconSrc
            }
          }
        case '/m':
        default:
          if (routePath === tab.href || routePath === `${tab.href}/`) {
            return {
              class: true,
              src: tab.iconActiveSrc
            }
          } else {
            return {
              class: false,
              src: tab.iconSrc
            }
          }
      }
    },
    handleClick(tab) {
      if (tab.children.length > 0) {
        this.isShowSubItem[tab.subType] = !this.isShowSubItem[tab.subType]
      } else {
        window.location.href = tab.href
      }
    },
    handleSubItemClick(item) {
      this.$router.push({ path: item.href })
      this.$store.commit('lottery/SET_DIRECT_ENTER_IMAGE', true) // 從 tab 直接進入圖庫，會設為 true
      this.isShowSubItem.pk10 = false
    }
  }
}
</script>

<style lang="scss" scoped>

  .subitem__wrapper {
    position: absolute;
    top: -60px;
  }

  .tabbar__subitem {
    width: 100px;
    border: 1px solid #d8d8d8;
    background: #ebe9e9;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    span {
      color: #161616;
    }
  }
</style>
