import request from '@/utils/request'

const getSiteInfo_desktop = function() {
  return request({
    url: '/v1/cp168/apis/site/info',
    method: 'get'
  })
}

const getDetailDrawInfo_desktop = function(code) {
  return request({
    url: `/v1/cp168/apis/draw/infoDetail?cpCode=${code}`,
    method: 'get'
  })
}

const getCpsList_desktop = function(categoryCode) {
  return request({
    url: `/v1/cp168/apis/cps?categoryCode=${categoryCode}`,
    method: 'get'
  })
}

const getRuleDetails_desktop = function(code, query) {
  return request({
    url: `/v1/cp168/apis/ruleDetails/${code}`,
    method: 'get',
    params: query
  })
}

export {
  getSiteInfo_desktop,
  getDetailDrawInfo_desktop,
  getCpsList_desktop,
  getRuleDetails_desktop
}
