import request from '@/utils/request'

export const rankingWins_web = function(query) {
  return request({
    url: '/v1/cp168/apis/rankingWins',
    method: 'get',
    params: query
  })
}

export const rankingProfits_web = function(query) {
  return request({
    url: '/v1/cp168/apis/rankingProfits',
    method: 'get',
    params: query
  })
}

export const latestPredictions_web = function(query) {
  return request({
    url: '/v1/cp168/apis/latestPredictions',
    method: 'get',
    params: query
  })
}

export const getPredictionStats_web = function(userID) {
  return request({
    url: `/v1/cp168/apis/predictionStats/${userID}`,
    method: 'get'
  })
}

export const predictionsHistories_web = function(userID, query) {
  return request({
    url: `/v1/cp168/apis/predictionsHistories/${userID}`,
    method: 'get',
    params: query
  })
}

export const getSubList_web = function(query) {
  return request({
    url: `/v1/cp168/apis/subscribedUsers`,
    method: 'get',
    params: query
  })
}

export const subscribe_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/subscribedUsers',
    method: 'post',
    data: payload
  })
}

export const cancelSub_web = function(userID) {
  return request({
    url: `/v1/cp168/apis/subscribedUsers/${userID}`,
    method: 'delete'
  })
}

export const renewToken_web = function() {
  return request({
    url: '/v1/cp168/apis/renewToken',
    method: 'post'
  })
}

export const signIn_web = function() {
  return request({
    url: '/v1/cp168/apis/me/signin',
    method: 'post'
  })
}

export const postPredictions_web = function(payload) {
  return request({
    url: '/v1/cp168/apis/predictions',
    method: 'post',
    data: payload
  })
}

export const checkPrediction_web = function(query) {
  return request({
    url: `/v1/cp168/apis/checkPrediction`,
    method: 'get',
    params: query
  })
}
