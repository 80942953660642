<template>
  <van-popup
    v-model="openPopUp"
    position="bottom"
    :style="{ height: '100%', background: '#EFEFF4'}"
    :get-container="getContainer"
  >
    <van-nav-bar
      title="所有彩种"
      left-text="返回"
      left-arrow
      :style="{ background: '#5dadff'}"
      @click-left="back"
    />
    <div class="wrap">
      <div
        v-for="(group, index) in list"
        :key="`lottery-group-${group.code}-${index}`"
        class="filter"
      >
        <div class="filter-title">
          <p class="title-lt" v-text="group.label"></p>
        </div>
        <ul class="filter-game">
          <li
            v-for="(child, childIndex) in group.children"
            :key="`lottery-group-${group.code}-${index}-${child.lotteryCode}-${childIndex}`"
            class="filter-game__item"
          >
            <a
              class="item-link"
              @click="switchLottery(child)"
              v-text="child.label"
            >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getCpsList_mobile } from '@/api/mobile/basic'

export default {
  name: 'HeaderPopUpContent',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      default: undefined,
      required: false
    },
    autoRedirect: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      openPopUp: false,

      defaultList: []
    }
  },
  computed: {
    list() {
      // TODO: options 的型別檢查
      return Array.isArray(this.options) ? this.options : this.defaultList
    }
  },
  watch: {
    show(val) {
      this.openPopUp = val
    }
  },
  async created() {
    const list = await this.getDefaultList()
    this.defaultList = list
  },
  methods: {
    back() {
      this.openPopUp = false
      this.$emit('update:show', this.openPopUp)
    },
    getContainer() {
      return document.querySelector('.layout-main')
    },
    async switchLottery(item) {
      this.openPopUp = false
      this.$emit('update:show', this.openPopUp)

      this.$emit('switchLottery', item)
      if (this.autoRedirect) {
        if (!item.link) {
          console.warn('switchLottery: 項目缺少 link 參數!', item)
          return
        }

        const { link } = item
        this.$router.push(link)
      }
    },
    async getDefaultList() {
      let jisuCps, gaopinCps, localCps, chinaCps
      let jisuCpsError = null
      let gaopinCpsError = null
      let localCpsError = null
      let chinaCpsError = null

      jisuCps = getCpsList_mobile('jisu')
      gaopinCps = getCpsList_mobile('gaopin')
      localCps = getCpsList_mobile('local')
      chinaCps = getCpsList_mobile('china');

      [
        [jisuCps, jisuCpsError],
        [gaopinCps, gaopinCpsError],
        [localCps, localCpsError],
        [chinaCps, chinaCpsError]
      ] = await Promise.all([jisuCps, gaopinCps, localCps, chinaCps])
      if (jisuCpsError || gaopinCpsError || localCpsError || chinaCpsError) {
        return
      }

      return [{
        label: '极速彩',
        children: jisuCps
      }, {
        label: '高频彩',
        children: gaopinCps
      }, {
        label: '地方彩',
        children: localCps
      }, {
        label: '全国彩',
        children: chinaCps
      }].reduce((array, group) => {
        group.children = group.children.map((child) => {
          return (({
            name: label,
            code: lotteryCode
          }) => ({
            label,
            lotteryCode,
            link: `/m/lottery/trend/${lotteryCode}`
          }))(child)
        })
        return array.concat(group)
      }, [])
    }
  }
}
</script>

<style scoped>
>>> .van-nav-bar__text {
  color: #fff;
}
>>> .van-nav-bar .van-icon {
  color:  #fff;
}
>>> .van-nav-bar__title {
  color:  #fff;
  font-size: 0.48309rem;
}
.van-popup.van-popup--bottom {
  display: flex;
  flex-direction: column;
}
.wrap {
  width: 100%;
  overflow-y: auto;
}
.filter {
  border-bottom: 1px solid #e4e3e8;
  padding-bottom: 13px;
}
.filter .filter-title {
  padding: 0px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filter .filter-title .title-lt {
  font-size: 16px;
  margin: 0px;
  line-height: 40px;
}
.filter .filter-game {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  padding: 0px 12px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
.filter .filter-game__item .item-link {
  display: -ms-grid;
  display: grid;
  text-align: center;
  text-decoration: none;
  background-color: #fff;
  position: relative;
  border-radius: 0.02rem;
  border: 1px solid #e5e4e9;
  color: #666666;
  font-size: 14px;
  padding: 10px 4px;
  white-space: normal;
  font-weight: normal;
  place-content: center;
  height: 100%;
}
</style>
