import {
  hasToken,
  getToken,
  setLoginInfo,
  getLoginInfo,
  removeLoginInfo,
  setUserInfo,
  removeUserInfo
} from '@/utils/member'

import { renewToken_mobile } from '@/api/mobile/betLottery'
import { renewToken_web } from '@/api/desktop/betLottery'

export const state = () => ({
  memberInfo: {
    token: '',
    userID: 0,
    userName: '',
    score: 0,
    fansCount: 0,
    avatarURL: ''
  },
  isLogin: false
})

export const getters = {
  getToken: () => {
    if (hasToken()) return getToken()
    else return ''
  }
}

export const mutations = {
  SET_LOGIN_INFO: function(state, payload) {
    state.memberInfo['token'] = payload.token
    state.memberInfo['userID'] = payload.userID
    state.memberInfo['userName'] = payload.userName
    state.isLogin = true
    setLoginInfo(payload)
  },
  SET_USER_INFO: function(state, payload) {
    state.memberInfo['score'] = payload.score
    state.memberInfo['fansCount'] = payload.fansCount
    state.memberInfo['avatarURL'] = payload.avatarURL
    setUserInfo(payload)
  },
  REMOVE_LOGIN_INFO: function(state) {
    state.memberInfo['token'] = ''
    state.memberInfo['userID'] = 0
    state.memberInfo['userName'] = ''
    state.isLogin = false
    removeLoginInfo()
  },
  REMOVE_USER_INFO: function(state) {
    state.memberInfo['score'] = 0
    state.memberInfo['fansCount'] = 0
    state.memberInfo['avatarURL'] = ''
    removeUserInfo()
  }
}

export const actions = {
  setLoginInfo: ({ commit }, payload) => {
    commit('SET_LOGIN_INFO', payload)
  },
  setUserInfo: async({ commit }, payload) => {
    commit('SET_USER_INFO', payload)
  },
  removeLoginInfo: ({ commit }) => {
    commit('REMOVE_LOGIN_INFO')
  },
  removeUserInfo: ({ commit }) => {
    commit('REMOVE_USER_INFO')
  },
  checkLocalStorage_web: async({ dispatch }) => {
    const loginInfo = getLoginInfo()
    if (loginInfo) {
      const [data, error] = await renewToken_web()
      if (error) dispatch('removeLoginInfo')
      else {
        const infoWithNewToken = loginInfo
        infoWithNewToken.token = data
        dispatch('setLoginInfo', infoWithNewToken)
      }
    } else dispatch('removeLoginInfo')
  },
  checkLocalStorage_mobile: async({ dispatch }) => {
    const loginInfo = getLoginInfo()
    if (loginInfo) {
      const [data, error] = await renewToken_mobile()
      if (error) dispatch('removeLoginInfo')
      else {
        const infoWithNewToken = loginInfo
        infoWithNewToken.token = data
        dispatch('setLoginInfo', infoWithNewToken)
      }
    } else dispatch('removeLoginInfo')
  }
}
