const state = () => ({

})

const mutations = {

}

const actions = {
  async nuxtServerInit({ dispatch }, { route }) {
    await dispatch('site/getSiteInfo')
    await dispatch('lottery/getPublicCp')
    // await dispatch('lottery/getCpsDetail')
  }
}

export { state, mutations, actions }
