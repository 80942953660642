import { lotteryTypeNameMapping } from '@/utils/lotteryDictionary'
import get from 'lodash.get'
import chunk from 'lodash.chunk'
import { ruleCodeMapping } from '@/utils/lotteryDictionary'
import { getMultiTrend_web } from '@/api/desktop/trend'

// 該彩種開出的數字位子，ex: 時時彩開5 個號碼, pk10 開10 個號碼
export const hmList = {
  hmList() {
    const lotteryType = this.lotteryType

    const _labelMap = {
      hm1: '第ㄧ名',
      hm2: '第二名',
      hm3: '第三名',
      hm4: '第四名',
      hm5: '第五名',
      hm6: '第六名',
      hm7: '第七名',
      hm8: '第八名',
      hm9: '第九名',
      hm10: '第十名'
    }
    const _labelMap_k3 = {
      hm1: '百位',
      hm2: '十位',
      hm3: '个位'
    }
    const _labelMap_pk10 = {
      hm1: '冠军',
      hm2: '亚军',
      hm3: '第三名',
      hm4: '第四名',
      hm5: '第五名',
      hm6: '第六名',
      hm7: '第七名',
      hm8: '第八名',
      hm9: '第九名',
      hm10: '第十名'
    }
    const _labelMap_11x5 = {
      hm1: '第ㄧ球',
      hm2: '第二球',
      hm3: '第三球',
      hm4: '第四球',
      hm5: '第五球'
    }
    const _labelMap_ssc = {
      hm1: '第ㄧ球',
      hm2: '第二球',
      hm3: '第三球',
      hm4: '第四球',
      hm5: '第五球'
    }
    const _labelMap_kl10 = {
      hm1: '第ㄧ球',
      hm2: '第二球',
      hm3: '第三球',
      hm4: '第四球',
      hm5: '第五球',
      hm6: '第六球',
      hm7: '第七球',
      hm8: '第八球'
    }

    const componentName = this.$options.name
    let returnArr = []
    switch (componentName) {
      // 奇偶比 奇偶走勢
      case 'JiOuZouShihKl10':
      case 'JiOuBiKl10':
        returnArr = (new Array(8)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap[`hm${index + 1}`] }))
        returnArr.push({ key: 'hmDanShuangRatio', lable: 'hmDanShuangRatio' }, { key: 'hmDanShuangDanCount', label: 'hmDanShuangDanCount' }, { key: 'hmDanShuangShuangCount', label: 'hmDanShuangShuangCount' })
        return returnArr
      case 'JiOuZouShihK3':
      case 'JiOuBiK3':
        returnArr = (new Array(3)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap[`hm${index + 1}`] }))
        returnArr.push({ key: 'hmDanShuangRatio', lable: 'hmDanShuangRatio' }, { key: 'hmDanShuangDanCount', label: 'hmDanShuangDanCount' }, { key: 'hmDanShuangShuangCount', label: 'hmDanShuangShuangCount' })
        return returnArr
        // 大小比 大小走勢
      case 'DaXiaoZouShihKl10':
      case 'DaXiaoBiKl10':
        returnArr = (new Array(8)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap[`hm${index + 1}`] }))
        returnArr.push({ key: 'hmDaXiaoRatio', lable: 'hmDaXiaoRatio' }, { key: 'hmDaXiaoDaCount', label: 'hmDaXiaoDaCount' }, { key: 'hmDaXiaoXiaoCount', label: 'hmDaXiaoXiaoCount' })
        return returnArr
      case 'DaXiaoZouShihK3':
      case 'DaXiaoBiK3':
        returnArr = (new Array(3)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap[`hm${index + 1}`] }))
        returnArr.push({ key: 'hmDaXiaoRatio', lable: 'hmDaXiaoRatio' }, { key: 'hmDaXiaoDaCount', label: 'hmDaXiaoDaCount' }, { key: 'hmDaXiaoXiaoCount', label: 'hmDaXiaoXiaoCount' })
        return returnArr
      // pk10 的冠亞和
      case 'GuanYaHeZouShih':
        return [{ key: 'sumguanya', label: 'sumguanya' }]

      case 'HeJhihZouShihBasic': // 和值走勢 基本
      case 'HeJhihZouShihXingTai': // 和值走勢 形態
      case 'LongHuZouShih': // 龍虎走勢
      case 'HaoMaFenBuZongHe': // 號碼分佈 總和
      case 'HaoMaFenBuSingTai': // 號碼分佈 型態
        return [{ key: 'all', label: 'all' }]
      case 'HaoMaFenBuBasic': // 號碼分佈 基本
        return [{ key: 'number', label: 'number' }]

      case 'WebJiBenZouShih': // 電腦版基本走勢
      case 'WebDaXiaoZouShih': // 電腦版大小走勢
      case 'WebJiOuZouShih': // 電腦版奇偶走勢
      case 'WebGuanYaHeZouShih': // 電腦版冠亞和走勢
      case 'WebHaoMaFenBu': // 電腦版號碼分佈
      case 'WebHeJhihZouShih': // 電腦版和值走勢
      case 'WebLongHuZouShih': // 電腦版龍虎走勢
      case 'WebXingTaiZouShih': // 電腦版型態走勢
        return [{ key: 'all', label: 'all' }]
    }

    switch (lotteryType) {
      case 'ssc':
        return (new Array(5)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_ssc[`hm${index + 1}`] }))
      case 'pk10':
        return (new Array(10)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_pk10[`hm${index + 1}`] }))
      case '11x5': // 兩種都是11選5
      case 'syx5': // 兩種都是11選5
        return (new Array(5)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_11x5[`hm${index + 1}`] }))
      case 'k3':
        return (new Array(3)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_k3[`hm${index + 1}`] }))
      case 'kl10':
        return (new Array(8)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_kl10[`hm${index + 1}`] }))
      default:
        console.warn('hmList: 透過lotteryType 取得對應的號碼列表失敗! 將使用空陣列.', lotteryType)
        return []
    }
  }
}

// 該彩種可以開出的數字選項，ex: 時時彩為0-9, pk10 為1-10 等
// 會依照欲呈現的圖表類型有不同的結果
export const numList = {
  numList() {
    const trendType = this.$options.name
    const lotteryType = this.lotteryType
    switch (trendType) {
      case 'PositionBasicTrend':
        return _basicTrend(lotteryType)
      case 'PositionLyeTrend':
        return _lyeTrend()
      case 'PositionSideTrend':
        return _sideTrend(lotteryType)
      case 'PositionSpjTrend':
        return _spjTrend()
      case 'GuanYaHeZouShih':
        return _guanYaHeZouShihTrend()
      case 'HeJhihZouShihBasic':
        return _heJhihZouShihBasic(lotteryType)
      case 'HeJhihZouShihXingTai':
        return _heJhihZouShihXingTai()
      case 'LongHuZouShih':
        return _longHuZouShih(lotteryType)
      case 'HaoMaFenBuBasic':
        return _haoMaFenBuBasic(lotteryType)
      case 'HaoMaFenBuZongHe':
        return _haoMaFenBuZongHe(lotteryType)
      case 'HaoMaFenBuSingTai':
        return _haoMaFenBuSingTai(lotteryType)
      case 'JiOuBiKl10':
        return _JiOuBikl10()
      case 'JiOuBiK3':
        return _JiOuBik3()
      case 'DaXiaoBiKl10':
        return _DaXiaoBiKl10()
      case 'DaXiaoBiK3':
        return _DaXiaoBiK3()
      case 'DaXiaoZouShihKl10':
        return _DaXiaoZouShihKl10()
      case 'JiOuZouShihKl10':
        return _JiOuZouShihKl10()
      case 'DaXiaoZouShihK3':
        return _DaXiaoZouShihK3()
      case 'JiOuZouShihK3':
        return _JiOuZouShihK3()

      // 電腦版
      case 'WebDingWeiZouShih': // 定位走勢
        return _webDingWeiZouShih(lotteryType)
      case 'WebJiBenZouShih': // 基本走勢
        return _webJiBenZouShih(lotteryType)
      case 'WebGuanYaHeZouShih': // 冠亞和走勢
        return _webGuanYaHeZouShih(lotteryType)
      case 'WebDaXiaoZouShih':
        return _webDaXiaoZouShih(lotteryType)
      case 'WebJiOuZouShih':
        return _webJiOuZouShih(lotteryType)
      case 'WebHeJhihZouShih': // 和值走勢
        return _webHeJhihZouShih(lotteryType)
      case 'WebHaoMaFenBu': // 號碼分佈
        return _webHaoMaFenBu(lotteryType)
      case 'WebLongHuZouShih': // 龍虎走勢
        return _webLongHuZouShih(lotteryType)
      default:
        console.warn(`numList: 對比trendType 失敗! 將使用空陣列`, trendType)
        return []
    }

    function _basicTrend(lotteryType) {
      let returnArray = []

      switch (lotteryType) {
        case 'ssc':
          returnArray = (new Array(10)).fill().map((nothing, index) => ({ label: `${index}`, key: `num${index}` }))
          return returnArray
        case 'pk10':
          returnArray = (new Array(9)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
          returnArray.push({
            label: '10',
            key: 'num10'
          })
          return returnArray
        case '11x5':
          returnArray = (new Array(9)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
          returnArray.push({
            label: '10',
            key: 'num10'
          }, {
            label: '11',
            key: 'num11'
          })
          return returnArray
        case 'k3':
          returnArray = (new Array(6)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
          return returnArray
        default:
          console.warn('_basicTrend: numList: 透過lotteryType 取得對應的location 列表失敗! 將使用空陣列', lotteryType)
          return []
      }
    }
    function _lyeTrend() {
      return (new Array(3)).fill().map((nothing, index) => ({ label: index.toString(), key: `num${index}` }))
    }
    function _sideTrend(lotteryType) {
      let keyList = []
      switch (lotteryType) {
        case 'ssc':
        case 'pk10':
        case 'k3':
          // 大小-大, 大小-小, 单双-单, 单双-双, 質合-質, 質合-合
          keyList = ['daxiao_da', 'daxiao_xiao', 'danshuang_dan', 'danshuang_shuang', 'jhihHe_jhih', 'jhihHe_he']
          return keyList.map((key) => ({ key }))
        case '11x5':
          keyList = ['daxiao_da', 'daxiao_xiao', 'daxiao_he', 'danshuang_dan', 'danshuang_shuang', 'danshuang_he', 'jhihHe_jhih', 'jhihHe_he']
          // 大小-大, 大小-小, 大小-和, 单双-单, 单双-双, 单双-和, 質合-質, 質合-合
          return keyList.map((key) => ({ key }))
        default:
          console.warn('_lyeTrend: numList: 透過lotteryType 取得對應的location 列表失敗! 將使用空陣列', lotteryType)
          return []
      }
    }
    function _spjTrend() {
      return [
        { key: 'shengPingJiang_sheng', label: '升' },
        { key: 'shengPingJiang_ping', label: '平' },
        { key: 'shengPingJiang_jiang', label: '降' }
      ]
    }
    function _guanYaHeZouShihTrend() {
      return (new Array(17)).fill().map((nothing, index) => ({ label: `${index + 3}`, key: `num${index + 3}` }))
    }
    function _heJhihZouShihBasic(lotteryType) {
      const numbers = lotteryType === 'k3' ? 16 : lotteryType === '11x5' ? 31 : 0
      const offset = lotteryType === 'k3' ? 3 : lotteryType === '11x5' ? 15 : 0
      return (new Array(numbers)).fill().map((nothing, index) => ({ label: `${index + offset}`, key: `num${index + offset}` }))
    }
    function _heJhihZouShihXingTai() {
      return [{
        key: 'daxiao_da'
      }, {
        key: 'daxiao_xiao'
      }, {
        key: 'daxiao_he'
      }, {
        key: 'danshuang_dan'
      }, {
        key: 'danshuang_shuang'
      }]
    }
    function _longHuZouShih() {
      const list = [{
        key: 'longHu_long',
        label: '龙'
      }, {
        key: 'longHu_hu',
        label: '虎'
      }]
      if (lotteryType === 'ssc') {
        list.push({
          key: 'longHu_he',
          label: '和'
        })
      }

      return list
    }
    function _haoMaFenBuBasic(lotteryType) {
      switch (lotteryType) {
        case 'k3':
          return (new Array(6)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
        case 'kl10':
          return (new Array(20)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
        default:
          console.warn('numList: _haoMaFenBuBasic: 此彩種沒有號碼分佈!', lotteryType)
      }
    }
    function _haoMaFenBuZongHe(lotteryType) {
      switch (lotteryType) {
        case 'kl10':
          return [{
            label: '和大',
            key: 'daxiao_da'
          }, {
            label: '和小',
            key: 'daxiao_xiao'
          }, {
            label: '大小和',
            key: 'daxiao_he'
          }, {
            label: '和单',
            key: 'danshuang_dan'
          }, {
            label: '和双',
            key: 'danshuang_shuang'
          }, {
            label: '尾大',
            key: 'mantissa_daxiao_da'
          }, {
            label: '尾小',
            key: 'mantissa_daxiao_xiao'
          }]
        default:
          console.warn('numList: _haoMaFenBuZongHe: 此彩種沒有號碼分佈!', lotteryType)
      }
    }
    function _haoMaFenBuSingTai(lotteryType) {
      return [{
        label: '豹子',
        key: 'puke_baoZih'
      }, {
        label: '三不同',
        key: 'puke_sanBuTong'
      }, {
        label: '对子',
        key: 'puke_dueiZih'
      }]
    }
    function _JiOuBikl10() {
      return [{
        key: '0to8'
      }, {
        key: '1to7'
      }, {
        key: '2to6'
      }, {
        key: '3to5'
      }, {
        key: '4to4'
      }, {
        key: '5to3'
      }, {
        key: '6to2'
      }, {
        key: '7to1'
      }, {
        key: '8to0'
      }]
    }
    function _JiOuBik3() {
      return [{
        key: '3to0'
      }, {
        key: '2to1'
      }, {
        key: '1to2'
      }, {
        key: '0to3'
      }]
    }
    function _DaXiaoZouShihKl10() {
      return [
        {
          key: 'daxiao_da'
        },
        {
          key: 'daxiao_xiao'
        }
      ]
    }
    function _JiOuZouShihKl10() {
      return [
        { key: 'danshuang_shuang' },
        { key: 'danshuang_dan' }

      ]
    }
    function _DaXiaoBiKl10() {
      return [{
        key: '0to8'
      }, {
        key: '1to7'
      }, {
        key: '2to6'
      }, {
        key: '3to5'
      }, {
        key: '4to4'
      }, {
        key: '5to3'
      }, {
        key: '6to2'
      }, {
        key: '7to1'
      }, {
        key: '8to0'
      }]
    }
    function _DaXiaoBiK3() {
      return [{
        key: '3to0'
      }, {
        key: '2to1'
      }, {
        key: '1to2'
      }, {
        key: '0to3'
      }]
    }
    function _DaXiaoZouShihK3() {
      return [
        {
          key: 'count0'
        },
        {
          key: 'count1'
        },
        {
          key: 'count2'
        },
        {
          key: 'count3'
        }
      ]
    }
    function _JiOuZouShihK3() {
      return [
        {
          key: 'count0'
        },
        {
          key: 'count1'
        },
        {
          key: 'count2'
        },
        {
          key: 'count3'
        }
      ]
    }

    // _web
    function _webDingWeiZouShih() {
      let returnArray = []
      let keyList = []
      let basicNumLength = 0
      let zeroPadding = 1

      switch (lotteryType) {
        case 'pk10':
        case 'k3':
        case '11x5':
        case 'ssc':
          if (lotteryType === 'pk10') basicNumLength = 10
          else if (lotteryType === 'k3') basicNumLength = 6
          else if (lotteryType === '11x5') basicNumLength = 11
          else if (lotteryType === 'ssc') { basicNumLength = 10; zeroPadding = 0 }

          returnArray = (new Array(basicNumLength)).fill()
            .map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + zeroPadding}` }))

          keyList = ['danshuang_dan', 'danshuang_shuang', 'daxiao_he', 'daxiao_da', 'daxiao_xiao', 'jhihHe_jhih', 'jhihHe_he']
          if (lotteryType !== '11x5') {
            keyList.splice(keyList.indexOf('daxiao_he'), 1) // 只有11x5 有大小和
          }

          // 形態特徵
          returnArray = returnArray.concat(keyList.map((key) => ({ label: ruleCodeMapping[key], key })))
          // 012 路
          returnArray = returnArray.concat((new Array(3)).fill().map((nothing, index) => ({ label: index.toString(), key: `lingyierlu_num${index}` })))
          // 升平降
          returnArray = returnArray.concat([
            { label: '升', key: 'shengPingJiang_sheng' },
            { label: '平', key: 'shengPingJiang_ping' },
            { label: '降', key: 'shengPingJiang_jiang' }
          ])
          break
        default:
          console.warn('_basicTrend: numList: 透過lotteryType 取得對應的location 列表失敗! 將使用空陣列', lotteryType)
      }
      return returnArray
    }
    function _webJiBenZouShih(lotteryType) {
      const list = []
      switch (lotteryType) {
        case 'pk10':
          for (let i = 0; i < 10; i++) {
            for (let j = 0; j < 10; j++) {
              const label = j + 1
              const key = `hm${i + 1}_num${j + 1}`
              list.push({ label, key })
            }
          }
          return list
        case 'ssc':
          for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 10; j++) {
              const label = j + 1
              const key = `hm${i + 1}_num${j}`
              list.push({ label, key })
            }
          }
          return list
        default:
          console.warn('_webJiBenZouShih: lotteryType 對應失敗! 將使用空陣列', lotteryType)
          return []
      }
    }
    function _webGuanYaHeZouShih() {
      return (new Array(17)).fill().map((nothing, index) => ({ label: `${index + 3}`, key: `sumguanya_num${index + 3}` }))
    }
    function _webDaXiaoZouShih(lotteryType) {
      const list = []
      switch (lotteryType) {
        case 'k3':
          for (let j = 0; j < 6; j++) {
            const label = j + 1
            const key = `number_num${j + 1}`
            list.push({ label, key })
          }
          for (let j = 0; j < 3; j++) {
            const daxiao_da_label = '大'
            const daxiao_xiao_label = '小'
            const daxiao_da_key = `hm${j + 1}_daxiao_da`
            const daxiao_xiao_key = `hm${j + 1}_daxiao_xiao`
            list.push({ label: daxiao_da_label, key: daxiao_da_key }, { label: daxiao_xiao_label, key: daxiao_xiao_key })
          }
          list.push(
            {
              label: '3:0', key: 'hmDaXiaoRatio_3to0'
            },
            {
              label: '2:1', key: 'hmDaXiaoRatio_2to1'
            },
            {
              label: '1:2', key: 'hmDaXiaoRatio_1to2'
            },
            {
              label: '0:3', key: 'hmDaXiaoRatio_0to3'
            }
          )
          for (let j = 0; j < 4; j++) {
            const label = j
            const key = `hmDaXiaoDaCount_count${j}`
            list.push({ label, key })
          }
          for (let j = 0; j < 4; j++) {
            const label = j
            const key = `hmDaXiaoXiaoCount_count${j}`
            list.push({ label, key })
          }
          return list
        case 'kl10':
          for (let j = 0; j < 8; j++) {
            const daxiao_da_label = '大'
            const daxiao_xiao_label = '小'
            const daxiao_da_key = `hm${j + 1}_daxiao_da`
            const daxiao_xiao_key = `hm${j + 1}_daxiao_xiao`
            list.push({ label: daxiao_da_label, key: daxiao_da_key }, { label: daxiao_xiao_label, key: daxiao_xiao_key })
          }
          list.push(
            {
              label: '0:8', key: 'hmDaXiaoRatio_0to8'
            },
            {
              label: '1:7', key: 'hmDaXiaoRatio_1to7'
            },
            {
              label: '2:6', key: 'hmDaXiaoRatio_2to6'
            },
            {
              label: '3:5', key: 'hmDaXiaoRatio_3to5'
            },
            {
              label: '4:4', key: 'hmDaXiaoRatio_4to4'
            },
            {
              label: '5:3', key: 'hmDaXiaoRatio_5to3'
            },
            {
              label: '6:2', key: 'hmDaXiaoRatio_6to2'
            },
            {
              label: '7:1', key: 'hmDaXiaoRatio_7to1'
            },
            {
              label: '8:0', key: 'hmDaXiaoRatio_8to0'
            }
          )
          return list
        default:
          console.warn('_webJiBenZouShih: lotteryType 對應失敗! 將使用空陣列', lotteryType)
          return []
      }
    }
    function _webJiOuZouShih(lotteryType) {
      const list = []
      switch (lotteryType) {
        case 'k3':
          for (let j = 0; j < 6; j++) {
            const label = j + 1
            const key = `number_num${j + 1}`
            list.push({ label, key })
          }
          for (let j = 0; j < 3; j++) {
            const danshuang_shuang_label = '偶'
            const danshuang_dan_label = '奇'
            const danshuang_shuang_key = `hm${j + 1}_danshuang_shuang`
            const danshuang_dan_key = `hm${j + 1}_danshuang_dan`
            list.push({ label: danshuang_dan_label, key: danshuang_dan_key }, { label: danshuang_shuang_label, key: danshuang_shuang_key })
          }
          list.push(
            {
              label: '3:0', key: 'hmDanShuangRatio_3to0'
            },
            {
              label: '2:1', key: 'hmDanShuangRatio_2to1'
            },
            {
              label: '1:2', key: 'hmDanShuangRatio_1to2'
            },
            {
              label: '0:3', key: 'hmDanShuangRatio_0to3'
            }
          )
          for (let j = 0; j < 4; j++) {
            const label = j
            const key = `hmDanShuangDanCount_count${j}`
            list.push({ label, key })
          }
          for (let j = 0; j < 4; j++) {
            const label = j
            const key = `hmDanShuangShuangCount_count${j}`
            list.push({ label, key })
          }
          return list
        case 'kl10':
          for (let j = 0; j < 8; j++) {
            const danshuang_shuang_label = '偶'
            const danshuang_dan_label = '奇'
            const danshuang_shuang_key = `hm${j + 1}_danshuang_shuang`
            const danshuang_dan_key = `hm${j + 1}_danshuang_dan`
            list.push({ label: danshuang_dan_label, key: danshuang_dan_key }, { label: danshuang_shuang_label, key: danshuang_shuang_key })
          }
          list.push(
            {
              label: '0:8', key: 'hmDanShuangRatio_0to8'
            },
            {
              label: '1:7', key: 'hmDanShuangRatio_1to7'
            },
            {
              label: '2:6', key: 'hmDanShuangRatio_2to6'
            },
            {
              label: '3:5', key: 'hmDanShuangRatio_3to5'
            },
            {
              label: '4:4', key: 'hmDanShuangRatio_4to4'
            },
            {
              label: '5:3', key: 'hmDanShuangRatio_5to3'
            },
            {
              label: '6:2', key: 'hmDanShuangRatio_6to2'
            },
            {
              label: '7:1', key: 'hmDanShuangRatio_7to1'
            },
            {
              label: '8:0', key: 'hmDanShuangRatio_8to0'
            })
          return list
        default:
          console.warn('_webJiBenZouShih: lotteryType 對應失敗! 將使用空陣列', lotteryType)
          return []
      }
    }
    function _webHeJhihZouShih(lotteryType) {
      switch (lotteryType) {
        case 'k3':
          return (new Array(16))
            .fill()
            .map((nothing, index) => ({ key: `all_num${index + 3}`, label: `${index + 3}` }))
            .concat([
              { key: 'all_daxiao_da', label: ruleCodeMapping['daxiao_da'] },
              { key: 'all_daxiao_xiao', label: ruleCodeMapping['daxiao_xiao'] },
              { key: 'all_daxiao_he', label: ruleCodeMapping['daxiao_he'] },
              { key: 'all_danshuang_dan', label: ruleCodeMapping['danshuang_dan'] },
              { key: 'all_danshuang_shuang', label: ruleCodeMapping['danshuang_shuang'] }
            ])
        case '11x5':
          return (new Array(31))
            .fill()
            .map((nothing, index) => ({ key: `all_num${index + 15}`, label: `${index + 15}` }))
            .concat([
              { key: 'all_daxiao_da', label: ruleCodeMapping['daxiao_da'] },
              { key: 'all_daxiao_xiao', label: ruleCodeMapping['daxiao_xiao'] },
              { key: 'all_daxiao_he', label: ruleCodeMapping['daxiao_he'] },
              { key: 'all_danshuang_dan', label: ruleCodeMapping['danshuang_dan'] },
              { key: 'all_danshuang_shuang', label: ruleCodeMapping['danshuang_shuang'] }
            ])
        default:
          console.warn('_webHeJhihZouShih: lotteryType 對應失敗!', lotteryType)
      }
    }
    function _webHaoMaFenBu(lotteryType) {
      let list = []
      switch (lotteryType) {
        case 'k3':
          list = (new Array(6)).fill().map((nothing, index) => ({ label: index + 1, key: `number_num${index + 1}` }))
          list = list.concat([
            { label: '豹子', key: 'all_puke_baoZih' },
            { label: '三不同', key: 'all_puke_sanBuTong' },
            { label: '对子', key: 'all_puke_dueiZih' }
          ])
          break
        case '11x5':
          list = (new Array(11)).fill().map((nothing, index) => ({ label: index + 1, key: `number_num${index + 1}` }))
          list = list.concat([{ label: '和值', key: 'zongHe', config: { custom: true }}])
          list = list.concat([
            { label: '大', key: 'all_daxiao_da' },
            { label: '小', key: 'all_daxiao_xiao' },
            { label: '和', key: 'all_daxiao_he' },

            { label: '单', key: 'all_danshuang_dan' },
            { label: '双', key: 'all_danshuang_shuang' },

            { label: '尾大', key: 'allmantissa_daxiao_da' },
            { label: '尾小', key: 'allmantissa_daxiao_xiao' }])
          break
        case 'kl10':
          list = (new Array(20)).fill().map((nothing, index) => ({ label: index + 1, key: `number_num${index + 1}` }))
          list = list.concat([{ label: '和值', key: 'zongHe', config: { custom: true }}])
          list = list.concat([
            { label: '大', key: 'all_daxiao_da' },
            { label: '小', key: 'all_daxiao_xiao' },
            { label: '和', key: 'all_daxiao_he' },

            { label: '单', key: 'all_danshuang_dan' },
            { label: '双', key: 'all_danshuang_shuang' },

            { label: '尾大', key: 'allmantissa_daxiao_da' },
            { label: '尾小', key: 'allmantissa_daxiao_xiao' }])
          break
        default:
          console.warn('_webHaoMaFenBu: lotteryType 對應失敗!', lotteryType)
      }
      return list
    }
    function _webLongHuZouShih(lotteryType) {
      let list = []
      switch (lotteryType) {
        case '11x5':
          list = list.concat((new Array(11))
            .fill()
            .map((nothing, index) => ({ label: `${index + 1}`, key: `hm1_num${index + 1}` })))
          list = list.concat((new Array(11))
            .fill()
            .map((nothing, index) => ({ label: `${index + 1}`, key: `hm5_num${index + 1}` })))
          list = list.concat([
            { label: '龙', key: 'all_longHu_long' },
            { label: '虎', key: 'all_longHu_hu' }
          ])
          break
        case 'ssc':
          list = list.concat((new Array(10))
            .fill()
            .map((nothing, index) => ({ label: `${index}`, key: `hm1_num${index}` })))
          list = list.concat((new Array(10))
            .fill()
            .map((nothing, index) => ({ label: `${index}`, key: `hm5_num${index}` })))
          list = list.concat([
            { label: '龙', key: 'all_longHu_long' },
            { label: '虎', key: 'all_longHu_hu' },
            { label: '和', key: 'all_longHu_he' }
          ])
          break
        default:
          console.warn('_webLongHuZouShih: lotteryType 對應失敗! 將使用空陣列.', lotteryType)
          list = []
      }
      return list
    }
  }
}

// 純粹只有數字的hmList
export const basicHmList = {
  basicHmList() {
    const _labelMap_k3 = {
      hm1: '百位',
      hm2: '十位',
      hm3: '个位'
    }
    const _labelMap_pk10 = {
      hm1: '冠军',
      hm2: '亚军',
      hm3: '第三名',
      hm4: '第四名',
      hm5: '第五名',
      hm6: '第六名',
      hm7: '第七名',
      hm8: '第八名',
      hm9: '第九名',
      hm10: '第十名'
    }
    const _labelMap_11x5 = {
      hm1: '第ㄧ球',
      hm2: '第二球',
      hm3: '第三球',
      hm4: '第四球',
      hm5: '第五球'
    }
    const _labelMap_ssc = {
      hm1: '第ㄧ球',
      hm2: '第二球',
      hm3: '第三球',
      hm4: '第四球',
      hm5: '第五球'
    }

    const { lotteryType } = this

    switch (lotteryType) {
      case 'ssc':
        return (new Array(5)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_ssc[`hm${index + 1}`] }))
      case 'pk10':
        return (new Array(10)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_pk10[`hm${index + 1}`] }))
      case '11x5':
        return (new Array(5)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_11x5[`hm${index + 1}`] }))
      case 'k3':
        return (new Array(3)).fill()
          .map((nothing, index) => ({ key: `hm${index + 1}`, label: _labelMap_k3[`hm${index + 1}`] }))
      default:
        console.warn('hmList: 透過lotteryType 取得對應的號碼列表失敗! 將使用空陣列.', lotteryType)
        return []
    }
  }
}
// 純粹只有數字的numList
export const basicNumList = {
  basicNumList() {
    let returnArray = []
    const { lotteryType } = this

    switch (lotteryType) {
      case 'ssc':
        returnArray = (new Array(10)).fill().map((nothing, index) => ({ label: `${index}`, key: `num${index}` }))
        return returnArray
      case 'pk10':
        returnArray = (new Array(9)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
        returnArray.push({
          label: '10',
          key: 'num10'
        })
        return returnArray
      case '11x5':
        returnArray = (new Array(9)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
        returnArray.push({
          label: '10',
          key: 'num10'
        }, {
          label: '11',
          key: 'num11'
        })
        return returnArray
      case 'k3':
        returnArray = (new Array(6)).fill().map((nothing, index) => ({ label: `${index + 1}`, key: `num${index + 1}` }))
        return returnArray
      default:
        console.warn('_basicTrend: numList: 透過lotteryType 取得對應的location 列表失敗! 將使用空陣列', lotteryType)
        return []
    }
  }
}

const mappingStatisticLabel = (statisticType) => {
  switch (statisticType) {
    case 'appearCount':
      return '出现次数'
    case 'avgMissing':
      return '平均遗漏'
    case 'now':
      return '当前遗漏'
    case 'biggestContinue':
      return '最大连出'
    case 'biggestMissing':
      return '最大遗漏'
    default:
      console.warn('mappingStatisticLabel: 統計對應失敗! 將使用連字號', statisticType)
      return '-'
  }
}

// 整理統計
export const formatStatisticList = function(trendStatistics = {}, statisticSort = [], { numList = [], hmList = [] } = {}) {
  if (trendStatistics.length === 0) return

  const tempMap = {}
  hmList.forEach(({ key: hmKey }) => {
    const hmStatistic = trendStatistics[hmKey]
    Object.keys(hmStatistic).forEach((statisticType) => {
      const statisticResult = hmStatistic[statisticType]
      tempMap[statisticType] = tempMap[statisticType] ? tempMap[statisticType] : {}

      // 做絕對值
      for (const numKey in statisticResult) {
        statisticResult[numKey] = statisticResult[numKey] === '-' ? statisticResult[numKey] : Math.abs(statisticResult[numKey])
      }

      tempMap[statisticType][hmKey] = numList.map((numItem) => {
        const numKey = numItem.key
        const number = statisticResult[numKey]
        return {
          number
        }
      })
    })
  })
  const returnList = statisticSort.reduce((list, statisticType) => {
    list.push({
      label: mappingStatisticLabel(statisticType),
      type: statisticType,
      data: tempMap[statisticType]
    })
    return list
  }, [])

  return returnList
}

// 遺漏走勢的共通methods
export const commonTrendMethods = {
  // 初始化
  init(data) {
    this.trendList = []
    this.statisticList = []
    this.removeTrendCanvas()

    if (!data) return // 通常代表資料還沒進來

    const trendListLength = get(data, 'trendList.length', 0)
    const trendStatistics = get(data, 'trendStatistics', {})

    if (trendListLength === 0 || Object.keys(trendStatistics) === 0) {
      console.warn(`當前彩種統計資料trendList${data.trendList}為空或trendStatistics${data.trendStatistics}為空, 將顯示字串 '目前尚無資料！'`)
      return
    }
    // format data
    this.trendList = this.formatTrendList(data.trendList)

    const numList = this.numList
    const hmList = this.hmList
    this.statisticList = formatStatisticList(data.trendStatistics, this.statisticSort, { numList, hmList })

    // 確認拆線
    this.checkCanvas()
  },
  // 確認拆線
  checkCanvas() {
    if (!this.presentLine) return

    this.removeTrendCanvas()
    this.$nextTick(() => {
      this.drawTrendCanvas()
    })
  }
}
// 電腦版走勢頁面的通用methods
export const commonTrendMethods_web = {
  // 取得資料，含前綴整理與後續綁定
  async getTrendData() {
    this.removeMultipleTrendCanvas() // TODO: 可以做成清除、不用把整個canvas 刪掉?

    const { lotteryCode, form, trendTypes, hmList, numList, lineArray, statisticSort } = this

    const [data, error] = await getMultiTrend_web(lotteryCode, trendTypes.join(','), form)
    if (error) return

    const preformatTrendDataFunc = typeof this.preformatTrendData === 'function'
      ? this.preformatTrendData
      : this.defaultPreformatTrendData
    const afterFormatTrendDataFunc = typeof this.afterFormatTrendData === 'function'
      ? this.afterFormatTrendData
      : this.defaultAfterFormatTrendData
    this.usingPreformatTrendDataFunc = preformatTrendDataFunc
    this.usingAfterFormatTrendDataFunc = afterFormatTrendDataFunc
    // 把比較不一樣的資料格式整理成一致的項目
    // 走勢
    const preformatData = this.usingPreformatTrendDataFunc(data)
    let list = this.formatTrendData(preformatData.records, hmList, numList, lineArray)
    list = this.usingAfterFormatTrendDataFunc(list)

    // 無限滾動使用的頁碼
    this._chunkInfo = {
      listPieces: [],
      currentPiecesIndex: 0,
      chunkSize: 30,
      intersectionObserver: null
    }
    const { _chunkInfo: chunkInfo } = this
    const { chunkSize, currentPiecesIndex } = chunkInfo

    chunkInfo.listPieces = chunk(list, chunkSize)
    const currentPiece = chunkInfo.listPieces[currentPiecesIndex]

    this.trendList = currentPiece

    // 数据统计
    const preformatStatisticFunc = typeof this.preformatStatistic === 'function'
      ? this.preformatStatistic
      : this.defaultPreformatStatistic
    const afterFormatStatisticFunc = typeof this.afterFormatStatistic === 'function'
      ? this.afterFormatStatistic
      : this.defaultAfterFormatStatistic
    this.usingPreformatStatisticFunc = preformatStatisticFunc
    this.usingAfterFormatStatisticFunc = afterFormatStatisticFunc

    const preformatStatistic = this.usingPreformatStatisticFunc(preformatData)
    let statisticList = this.formatStatisticData(preformatStatistic.stats, hmList, statisticSort)
    statisticList = this.usingAfterFormatStatisticFunc(statisticList)

    this.statisticList = statisticList

    this.$nextTick(() => {
      if (this.trendList.length && this.presentLine) this.drawMultiTrendCanvas()
    })
  },
  defaultPreformatTrendData(data) {
    const records = []
    const stats = {}

    // records
    data.records.forEach((item) => {
      const { period, balls } = item
      const returnObject = { period, balls, missing: {}}

      Object.keys(item.missing).forEach((trendType) => {
        returnObject.missing[trendType] = { all: {}} // all 是客製的

        Object.keys(item.missing[trendType]).forEach((hmKey) => {
          Object.keys(item.missing[trendType][hmKey]).forEach((numKey) => {
            const missingValue = item.missing[trendType][hmKey][numKey]
            const missingKey = `${hmKey}_${numKey}`

            returnObject.missing[trendType].all[missingKey] = missingValue
          })
        })
      })

      records.push(returnObject)
    })

    // stats
    Object.keys(data.stats).forEach((statisticType) => {
      stats[statisticType] = {}

      Object.keys(data.stats[statisticType]).forEach((trendType) => {
        stats[statisticType][trendType] = { all: {}} // all 是客製的

        Object.keys(data.stats[statisticType][trendType]).forEach((hmKey) => {
          Object.keys(data.stats[statisticType][trendType][hmKey]).forEach((numKey) => {
            const statisticValue = data.stats[statisticType][trendType][hmKey][numKey]
            const statisticKey = `${hmKey}_${numKey}`

            stats[statisticType][trendType].all[statisticKey] = statisticValue
          })
        })
      })
    })

    return { records, stats }
  },
  formatTrendData(records, hmList = this.hmList, numList = this.numList, lineArray = this.lineArray) {
    const missingLayerCounter = createMissingLayerCounter(hmList, numList)
    const numListCounter = numList.reduce((map, { key, config = {}}) => {
      if (config.custom) return map
      return Object.assign(map, { [key]: false })
    }, {})

    let list = records.map((item) => {
      const { period, balls } = item
      const returnObject = { period, balls, missing: {}}

      Object.keys(item.missing).forEach((trendType) => {
        const trendHmMap = item.missing[trendType]

        hmList.forEach((hm) => {
          const { key: hmKey } = hm
          const trendHmItem = trendHmMap[hmKey]

          returnObject.missing[hmKey] = returnObject.missing[hmKey] ? returnObject.missing[hmKey] : {}

          Object.keys(trendHmItem).forEach((missingValueType) => {
            // 每個单一框格的資料都在這裡

            let numKey = missingValueType

            // 目前僅有012 路的num0, num1, num2 會和basicTrend 的num1, num2... 撞key
            // 所以給012 路一個自己的key prefix
            if (trendType === 'hmLingYiErLu') {
              numKey = `lingyierlu_${missingValueType}`
            }
            numListCounter[numKey] = true

            const missingNumber = Math.abs(trendHmItem[missingValueType])
            const isDraw = missingNumber === 0
            const label = isDraw ? ruleCodeMapping[missingValueType] : missingNumber
            if (label === undefined) {
              console.warn('ruleCodeMapping 對應失敗!', missingValueType)
            }

            if (isDraw) {
              missingLayerCounter[hmKey][numKey] = false
            }
            const isMissingLayer = missingLayerCounter[hmKey][numKey]

            // 準備畫canvas 的部分:
            // 同樣因為num 的問題，所以將其區分出來變為同一個drawType
            const drawMap = {}
            lineArray.forEach((canvasItem) => {
              const canvasType = typeof canvasItem === 'string' ? canvasItem : canvasItem.key

              // hm 的特殊處理:
              // 如果不以 _ 區分的話，hm1 會和 hm10 進到同一個項目..
              // TODO: 優化?
              const regexp = /^hm/.test(canvasType) ? (new RegExp(`^${canvasType}_`)) : (new RegExp(`^${canvasType}`))
              drawMap[canvasType] = regexp.test(numKey) && isDraw
            })

            let drawType = numKey.split('_')[0]
            drawType = /^num\d+$/.test(drawType) ? 'num' : drawType

            // 顏色
            const isOrange = isDraw && _isOrange(numKey)
            const isBlue = isDraw && _isBlue(numKey)
            const isRed = isDraw && _isRed(numKey)
            const colorMap = { isOrange, isBlue, isRed }

            returnObject.missing[hmKey][numKey] = Object.assign({ label, isDraw, isMissingLayer, drawType, drawMap }, colorMap)
          })
        })
      })
      return returnObject
    })
    if (Object.keys(numListCounter).filter((key) => !numListCounter[key]).length) {
      console.error('頁面指定的numList 和api 資料回傳不一樣, 請檢查頁面需求.', numListCounter, numList)
      list = fullfilledMissing(list, hmList, numList)
    }

    return list

    function _isOrange(numKey) {
      switch (numKey) {
        case 'danshuang_dan':
        case 'daxiao_da':
        case 'jhihHe_jhih':
        case 'lingyierlu_num0':
        case 'lingyierlu_num2':
        case 'shengPingJiang_ping':
        case 'all_danshuang_dan':
        case 'all_daxiao_da':
        case 'all_jhihHe_jhih':
        case 'all_lingyierlu_num0':
        case 'all_lingyierlu_num2':
        case 'all_shengPingJiang_ping':
        case 'all_longHu_long':
        case 'all_puke_baoZih':
        case 'all_puke_dueiZih':
        case 'allmantissa_daxiao_da':
        case 'hm1_daxiao_da':
        case 'hm2_daxiao_da':
        case 'hm3_daxiao_da':
        case 'hm4_daxiao_da':
        case 'hm5_daxiao_da':
        case 'hm6_daxiao_da':
        case 'hm7_daxiao_da':
        case 'hm8_daxiao_da':
        case 'hm1_danshuang_shuang':
        case 'hm2_danshuang_shuang':
        case 'hm3_danshuang_shuang':
        case 'hm4_danshuang_shuang':
        case 'hm5_danshuang_shuang':
        case 'hm6_danshuang_shuang':
        case 'hm7_danshuang_shuang':
        case 'hm8_danshuang_shuang':
        case 'hmDaXiaoRatio_3to0':
        case 'hmDanShuangRatio_3to0':
        case 'hmDaXiaoRatio_1to2':
        case 'hmDanShuangRatio_1to2':
        case 'group1_zuSyuan_baoZih':
        case 'group1_zuSyuan_zuLiou':
        case 'group1_zuSyuan_zuSan':
        case 'group2_zuSyuan_baoZih':
        case 'group2_zuSyuan_zuLiou':
        case 'group2_zuSyuan_zuSan':
        case 'group3_zuSyuan_baoZih':
        case 'group3_zuSyuan_zuLiou':
        case 'group3_zuSyuan_zuSan':
        case 'all_longHu_he':
          return true
        default:
          return false
      }
    }
    function _isBlue(numKey) {
      switch (numKey) {
        case 'danshuang_shuang':
        case 'daxiao_xiao':
        case 'jhihHe_he':
        case 'lingyierlu_num1':
        case 'shengPingJiang_sheng':
        case 'shengPingJiang_jiang':
        case 'all_danshuang_shuang':
        case 'all_daxiao_xiao':
        case 'all_jhihHe_he':
        case 'all_lingyierlu_num1':
        case 'all_shengPingJiang_sheng':
        case 'all_shengPingJiang_jiang':
        case 'all_longHu_hu':
        case 'all_puke_sanBuTong':
        case 'allmantissa_daxiao_xiao':
        case 'hmDanShuangRatio_2to1':
        case 'hmDaXiaoRatio_2to1':
        case 'hmDanShuangRatio_0to3':
        case 'hmDaXiaoRatio_0to3':
        case 'hm1_daxiao_xiao':
        case 'hm2_daxiao_xiao':
        case 'hm3_daxiao_xiao':
        case 'hm4_daxiao_xiao':
        case 'hm5_daxiao_xiao':
        case 'hm6_daxiao_xiao':
        case 'hm7_daxiao_xiao':
        case 'hm8_daxiao_xiao':
        case 'hm1_danshuang_dan':
        case 'hm2_danshuang_dan':
        case 'hm3_danshuang_dan':
        case 'hm4_danshuang_dan':
        case 'hm5_danshuang_dan':
        case 'hm6_danshuang_dan':
        case 'hm7_danshuang_dan':
        case 'hm8_danshuang_dan':
        case 'group1_puke_banShun':
        case 'group1_puke_baoZih':
        case 'group1_puke_dueiZih':
        case 'group1_puke_shunZih':
        case 'group1_puke_zaLiou':
        case 'group2_puke_banShun':
        case 'group2_puke_baoZih':
        case 'group2_puke_dueiZih':
        case 'group2_puke_shunZih':
        case 'group2_puke_zaLiou':
        case 'group3_puke_banShun':
        case 'group3_puke_baoZih':
        case 'group3_puke_dueiZih':
        case 'group3_puke_shunZih':
        case 'group3_puke_zaLiou':
          return true
        default:
          return false
      }
    }
    function _isRed(numKey) {
      switch (numKey) {
        case 'hmDanShuangRatio_0to8':
        case 'hmDanShuangRatio_1to7':
        case 'hmDanShuangRatio_2to6':
        case 'hmDanShuangRatio_3to5':
        case 'hmDanShuangRatio_4to4':
        case 'hmDanShuangRatio_5to3':
        case 'hmDanShuangRatio_6to2':
        case 'hmDanShuangRatio_7to1':
        case 'hmDanShuangRatio_8to0':
        case 'hmDaXiaoRatio_0to8':
        case 'hmDaXiaoRatio_1to7':
        case 'hmDaXiaoRatio_2to6':
        case 'hmDaXiaoRatio_3to5':
        case 'hmDaXiaoRatio_4to4':
        case 'hmDaXiaoRatio_5to3':
        case 'hmDaXiaoRatio_6to2':
        case 'hmDaXiaoRatio_7to1':
        case 'hmDaXiaoRatio_8to0':
        case 'daxiao_he':
        case 'danshuang_he':
        case 'all_daxiao_he':
          return true
        default:
          return false
      }
    }
  },
  defaultAfterFormatTrendData(list) {
    return list
  },
  defaultPreformatStatistic(data) {
    return data
  },
  formatStatisticData(stats, hmList = this.hmList, statisticSort = this.statisticSort) {
    // TODO: 整理，如轉至記憶體位置等

    const list = this.statisticSort.map((statisticType) => {
      const label = ruleCodeMapping[statisticType]
      const returnObject = { label, statisticType, data: {}}
      const statisticTypeItem = stats[statisticType]

      Object.keys(statisticTypeItem).forEach((trendType) => {
        hmList.forEach(({ key: hmKey }) => {
          returnObject.data[hmKey] = returnObject.data[hmKey] ? returnObject.data[hmKey] : {}

          const statisticTypeHmItem = statisticTypeItem[trendType][hmKey]

          Object.keys(statisticTypeHmItem).forEach((missingValueType) => {
            let numKey = missingValueType

            // 目前僅有012 路的num0, num1, num2 會和basicTrend 的num1, num2... 撞key
            // 所以給012 路一個自己的key prefix
            if (trendType === 'hmLingYiErLu') {
              numKey = `lingyierlu_${missingValueType}`
            }

            const value = Math.abs(statisticTypeHmItem[missingValueType])
            returnObject.data[hmKey][numKey] = value
          })
        })
      })
      return returnObject
    })

    return list
  },
  defaultAfterFormatStatistic(list) {
    return list
  },

  changePresent(present) {
    if (this.present === undefined) {
      console.warn('changePresent: vue component 實體缺少present 物件!')
      return
    }
    Object.assign(this.present, present)
  },
  async updateForm(form) {
    this.isLoading = true
    if (!this.form) {
      console.warn('updateForm: this.form 取得失敗!', this.form)
      return
    }
    this.form = Object.assign({}, form)
    await this.getTrendData()
    this.isLoading = false
    this.$nextTick(() => {
      if (this.trendList.length && this.presentLine) this.drawMultiTrendCanvas()
    })
  },

  initLazyLoading() {
    if (this._chunkInfo.intersectionObserver) return

    const lastOne = document.querySelector('[statistic-dom]')
    if (!lastOne) return

    this._chunkInfo.intersectionObserver = new IntersectionObserver(onEnterView)
    this._chunkInfo.intersectionObserver.observe(lastOne)

    const {
      _chunkInfo: chunkInfo
    } = this
    const vm = this

    function onEnterView(entries, observer) {
      for (const entry of entries) {
        if (!entry.isIntersecting) continue

        chunkInfo.currentPiecesIndex++
        const currentPiece = chunkInfo.listPieces[chunkInfo.currentPiecesIndex]
        if (!currentPiece) {
          vm._chunkInfo.intersectionObserver.unobserve(lastOne)
          return
        }

        vm.trendList = vm.trendList.concat(currentPiece)

        vm.$nextTick(() => {
          if (vm.trendList.length && vm.presentLine) vm.drawMultiTrendCanvas()
        })
      }
    }
  }
}

export const isLotteryType = {
  isHk6() {
    return this.lotteryType === 'hk6'
  },
  isSsc() {
    return this.lotteryType === 'ssc'
  },
  isPk10() {
    return this.lotteryType === 'pk10'
  },
  isXy28() {
    return this.lotteryType === 'xy28'
  },
  is11x5() {
    return this.lotteryType === '11x5'
  },
  isK3() {
    return this.lotteryType === 'k3'
  },
  iskl10() {
    return this.lotteryType === 'kl10' || this.lotteryType === 'xync'
  }
}

// 用來畫遺漏分層用的
export const createMissingLayerCounter = function(hmList, numList) {
  if (!Array.isArray(hmList) || !Array.isArray(numList)) {
    console.warn('missingLayerCounter: 參數皆須為Array', hmList, numList)
    return {}
  }
  return hmList.reduce((hmMap, hm) => {
    const hmNumberObj = numList.reduce((numMap, num) => Object.assign(numMap, { [num.key]: true }), {})
    return Object.assign(hmMap, { [hm.key]: hmNumberObj })
  }, {})
}

// 取得球的class
export const getBallClass = function(number, lotteryType = '') {
  switch (lotteryType) {
    case 'ssc':
    case '11x5':
    case 'kl10':
      return ''
    case 'pk10':
    case 'k3':
      return number < 10 ? `ball-0${number}` : `ball-${number}`
    default:
      console.warn('getBallClass: 取得對應的ballClass 失敗! 將使用空字串', lotteryType)
      return ''
  }
}

export const presentSelectorComputedVariable = {
  presentMissing() {
    const presetMap = this.present ? this.present : {}
    return presetMap.presentMissing
  },
  presentLine() {
    const presetMap = this.present ? this.present : {}
    return presetMap.presentLine
  },
  presentLineDivided() {
    const presetMap = this.present ? this.present : {}
    return presetMap.presentLineDivided
  },
  presentLineMissingLayer() {
    const presetMap = this.present ? this.present : {}
    return presetMap.presentLineMissingLayer
  }
}

export const linkElement = function(options = {}) {
  options.rootKey = options.rootKey ? options.rootKey : '[draw-root]' // 畫布座標的原點
  options.elementKey = options.elementKey ? options.elementKey : '[draw-me="true"]' // 要被連起來的點的共通點
  options.canvasId = options.canvasId ? options.canvasId : 'default-link_element-canvas-id' // 用來區分獨立畫布的時候使用
  options.setting = options.setting ? options.setting : {}
  options.canvasStyle = options.canvasStyle ? options.canvasStyle : {}
  options.eraserSize = options.eraserSize ? options.eraserSize : 15
  options.maxHeight = options.maxHeight ? options.maxHeight : 1000

  const eraserSize = options.eraserSize

  // 覆寫制的設定們
  // const defaultSetting = {}
  const defaultCanvasStyle = {
    strokeStyle: '#000',
    lineWidth: 2,
    lineCap: 'round',
    lineJoin: 'round'
  }
  const runningCanvasStyle = Object.assign(defaultCanvasStyle, options.canvasStyle)

  // 幫座標元素定位對應的值
  const root = document.querySelector(options.rootKey)
  if (!root) {
    console.error('linkElement: rootKey 在畫面上找不到相符的element', options.rootKey)
    return false
  }
  const canvas_width = root.offsetWidth
  const canvas_height = root.offsetHeight

  let points = document.querySelectorAll(options.elementKey)
  if (points.length === 0) {
    console.error('linkElement: elementKey 在畫面上沒有找到任何相符的element', options.elementKey)
    return false
  }

  // 繪圖邏輯開始
  points = [].map.call(points, (item) => {
    const axis = _getAxis(item, root)
    const width = item.offsetWidth
    const height = item.offsetHeight
    const x = axis.x
    const y = axis.y
    const cx = x + width / 2
    const cy = y + height / 2
    return {
      element: item,
      x,
      y,
      cx,
      cy,
      width,
      height
    }
  })

  // 由於canvas 有尺寸限制、所以用有疊合的canvas 們 做出整個大張的圖
  const canvasInfoArray = [{ top: 0, left: 0, height: 0, width: canvas_width, points: [] }]
  let accumMaxHeightTimes = 1
  let accumMaxHeight = accumMaxHeightTimes * options.maxHeight
  let currentCanvasArrayIndex = 0
  points.forEach((point, index) => {
    if (point.y >= accumMaxHeight) {
      canvasInfoArray[currentCanvasArrayIndex].height = point.y - canvasInfoArray[currentCanvasArrayIndex].top

      accumMaxHeightTimes++
      currentCanvasArrayIndex++
      accumMaxHeight = options.maxHeight * accumMaxHeightTimes

      const previousPoint = points[index - 1]

      canvasInfoArray[currentCanvasArrayIndex] = { top: previousPoint.y, left: 0, height: 0, width: canvas_width, points: [{ ...previousPoint }] }
    }
    canvasInfoArray[currentCanvasArrayIndex].points.push({ ...point })
  })
  canvasInfoArray[currentCanvasArrayIndex].height = canvas_height - canvasInfoArray[currentCanvasArrayIndex].top // 幫最後一個canvasDOM 加上高度

  window.canvasInfoArray = canvasInfoArray

  // canvas前置整理
  canvasInfoArray.forEach((canvasItem, canvasIndex) => {
    // 計算canvas 尺寸
    const sortArray = canvasItem.points.slice().sort((a, b) => a.cx - b.cx)
    canvasItem.minX = sortArray.slice(0, 1)[0].cx
    canvasItem.maxX = sortArray.slice(-1)[0].cx

    canvasItem.left = canvasItem.minX
    canvasItem.width = canvasItem.maxX - canvasItem.minX

    const selectKey = `canvas[link_element-canvas-id="${options.canvasId}"][link_element-canvas-sequence="${canvasIndex}"]`
    const previousDOM = document.querySelector(selectKey)
    const canvasDOM = previousDOM !== null ? previousDOM : document.createElement('canvas')
    canvasDOM.width = canvasItem.width
    canvasDOM.height = canvasItem.height

    canvasDOM.style.position = 'absolute'
    canvasDOM.style.top = `${canvasItem.top}px`
    canvasDOM.style.left = `${canvasItem.left}px`

    canvasDOM.setAttribute('link_element-canvas-id', options.canvasId)
    canvasDOM.setAttribute('link_element-canvas-sequence', canvasIndex)

    root.appendChild(canvasDOM)

    _draw_canvas_line(canvasDOM, canvasItem, runningCanvasStyle)
  })
  // 開始畫canvas
  function _draw_canvas_line(canvas, canvasInfo, runningCanvasStyle) {
    const c = canvas.getContext('2d')
    Object.assign(c, runningCanvasStyle)

    const { points } = canvasInfo

    // 整理偏移值
    points.forEach((point) => {
      point.cx = point.cx - canvasInfo.left
      point.cy = point.cy - canvasInfo.top
    })

    c.beginPath()
    c.moveTo(points[0].cx, points[0].cy)
    for (let i = 1; i < points.length; i++) {
      const point = points[i]
      c.lineTo(point.cx, point.cy)
    }
    c.stroke()
    c.closePath()

    // 疊合消去:
    // 這裡視情況看要不要做save 和restore 的補正
    c.globalCompositeOperation = 'destination-out'
    c.fillStyle = 'black' // 實心顏色即可
    for (let i = 0; i < points.length; i++) {
      c.beginPath()
      const point = points[i]
      c.arc(point.cx, point.cy, eraserSize, 0, 2 * Math.PI, false)
      c.fill()
    }
    c.closePath()
  }

  function _getAxis(element, root) {
    // 修改
    // https://stackoverflow.com/questions/1480133/how-can-i-get-an-objects-absolute-position-on-the-page-in-javascript
    var x = 0
    var y = 0

    do {
      x += element.offsetLeft
      y += element.offsetTop
      element = element.offsetParent
    } while (element !== root && element)

    return {
      x: x,
      y: y
    }
  }
}

export const drawTrendCanvas = {
  drawTrendCanvas(color = '#178aff', lineWidth = 2) {
    this.$nextTick(() => {
      linkElement({
        canvasStyle: {
          strokeStyle: color,
          lineWidth
        }
      })
    })
  },
  drawMultiTrendCanvas(color = '#178aff', lineWidth = 2, lineArray = this.lineArray) {
    this.$nextTick(() => {
      const canvasArray = Array.isArray(lineArray) ? lineArray : ['draw-me']

      for (let i = 0; i < canvasArray.length; i++) {
        const canvasItem = canvasArray[i]
        let elementKey = ''
        const canvasStyle = {
          strokeStyle: color,
          lineWidth
        }

        if (typeof canvasItem === 'string') {
          elementKey = `[${canvasItem}="true"]`
        } else {
          elementKey = `[${canvasItem.key}="true"]`
          canvasItem.canvasStyle = canvasItem.canvasStyle ? canvasItem.canvasStyle : {}

          Object.assign(canvasStyle, canvasItem.canvasStyle)
        }

        linkElement({
          elementKey,
          canvasId: `canvasId-${i}`,
          canvasStyle
        })
      }
    })
  },
  removeMultipleTrendCanvas(selector = '[link_element-canvas-id]') {
    const canvasArray = document.querySelectorAll(selector)

    for (let i = 0; i < canvasArray.length; i++) {
      const canvasDOM = canvasArray[i]
      if (!canvasDOM) continue

      const c = canvasDOM.getContext('2d')
      c.clearRect(0, 0, canvasDOM.width, canvasDOM.height)
    }
  },
  removeTrendCanvas(selector = '[link_element-canvas-id]') {
    const canvasDOM = document.querySelector(selector)
    if (!canvasDOM) return
    const c = canvasDOM.getContext('2d')
    c.clearRect(0, 0, canvasDOM.width, canvasDOM.height)
  }
}

// 遺漏的預設值
export const createDefaultMissing = function(hmList, numList) {
  hmList = Array.isArray(hmList) ? hmList : false

  if (!hmList) {
    console.warn('createDefaultMissing: 缺少hmList 參數或型別錯誤! 將回傳空物件', hmList)
    return {}
  }
  return hmList
    .reduce((hmObj, hmItem) => {
      hmObj[hmItem.key] = numList
        .reduce((numObj, numItem) => Object.assign(numObj, { [numItem.key]: '-' }), {})
      return hmObj
    }, {})
}
// 如果numList 和api 回傳的對應不起來的預設資料
export const fullfilledMissing = function(list, hmList, numList) {
  const array = JSON.parse(JSON.stringify(list))
  array.forEach((item) => {
    hmList.forEach(({ key: hmKey }) => {
      numList.forEach(({ key: numKey }) => {
        item.missing[hmKey][numKey] = item.missing[hmKey][numKey] ? item.missing[hmKey][numKey] : {}
      })
    })
  })
  return array
}

// 用來產生預設值的function
export const sideNumListKeyMap = function(lotteryType, statisticType) {
  switch (lotteryType) {
    case 'hk6':
    case 'hk':
    case 'ssc':
    case 'pk10':
    case 'k3':
    default:
      switch (statisticType) {
        case 'daXiao':
          return _numListCreator(['daxiao_da', 'daxiao_xiao'])
        case 'danShuang':
          return _numListCreator(['danshuang_dan', 'danshuang_shuang'])
        case 'jhihHe':
          return _numListCreator(['jhihHe_jhih', 'jhihHe_he'])
      }
      break
    case '11x5':
      switch (statisticType) {
        case 'daXiao':
          return _numListCreator(['daxiao_da', 'daxiao_xiao', 'daxiao_he'])
        case 'danShuang':
          return _numListCreator(['danshuang_dan', 'danshuang_shuang', 'danshuang_he'])
        case 'jhihHe':
          return _numListCreator(['jhihHe_jhih', 'jhihHe_he'])
      }
      break
  }
  function _numListCreator(unHandled) {
    return unHandled.map((item) => ({ key: item }))
  }
}

// 預測相關頁面的computed 引入
export const predictionsLotteryList = {
  // 兩面推薦
  lmtjLotteryList() {
    const lmtjLotteryTypeItems = this.$store.state.lottery.liangMian.lotteryTypeItems

    const lmtjLink = /^\/m\//.test(this.$route.path) ? '/m/lmtj' : '/lmtj'
    const lmtjLotteryList = formatPredictionLotteryList(lmtjLotteryTypeItems, lmtjLink)
    return lmtjLotteryList
  },
  // 殺號計畫
  shJhLotteryList() {
    const shJhLotteryTypeItems = this.$store.state.lottery.shaHao.lotteryTypeItems

    const shjhLink = /^\/m\//.test(this.$route.path) ? '/m/shjh' : '/shjh'
    const shJhLotteryList = formatPredictionLotteryList(shJhLotteryTypeItems, shjhLink)
    return shJhLotteryList
  },
  // 專家推薦
  expertLotteryList() {
    const expertLotteryTypeItems = this.$store.state.lottery.zhuanJia.lotteryTypeItems

    const expertLink = /^\/m\//.test(this.$route.path) ? '/m/expert' : '/expert'
    const expertLotteryList = formatPredictionLotteryList(expertLotteryTypeItems, expertLink)
    return expertLotteryList
  },
  // 專家推薦電腦版
  expertLotteryListWeb() {
    const expertLotteryTypeItems = this.$store.state.lottery.zhuanJia.lotteryTypeItems

    const expertLink = '/expert'
    const expertLotteryList = formatPredictionLotteryList(expertLotteryTypeItems, expertLink)
    return expertLotteryList
  },
  // 購彩計畫
  buyplanLotteryList() {
    const buyplanLotteryTypeItems = this.$store.state.lottery.gouCai.lotteryTypeItems

    const buyPlanLink = /^\/m\//.test(this.$route.path) ? '/m/buyplan' : '/buyplan'
    const buyplanLotteryList = formatPredictionLotteryList(buyplanLotteryTypeItems, buyPlanLink)
    return buyplanLotteryList
  },
  // 競猜
  lotterybetLotteryList() {
    const lotterybetLotteryTypeItems = this.$store.state.lottery.jingCai.lotteryTypeItems
    const lotterybetLotterylist = formatPredictionLotteryList(lotterybetLotteryTypeItems)
    return lotterybetLotterylist
  },
  lotterybetLotteryList_web() {
    const lotterybetLotteryTypeItems = this.$store.state.lottery.jingCai.lotteryTypeItems
    const lotteryBetLink = this.$route.path
    const lotterybetLotterylist = formatPredictionLotteryList(lotterybetLotteryTypeItems, lotteryBetLink, { isLotteryBetWeb: true, currentLottery: this.$route.params.lottery_code })
    return lotterybetLotterylist
  },
  // 電腦版走勢
  webLotteryTrendList() {
    const lotteryTrendTypeItems = this.$store.state.lottery.all.lotteryTypeItems
    const trendLink = '/lottery/trend'
    const lotteryTrendList = formatPredictionLotteryList(lotteryTrendTypeItems, trendLink)

    return lotteryTrendList
  }
}

// 預測相關頁面的共用dispatch
export const dispatchLotteryList = function(app) {
  const routeName = app.$route.name.toLowerCase()

  switch (routeName) {
    // 兩面推薦
    case 'm-lmtj-lottery_code':
    case 'lmtj-lottery_code':
      app.$store.dispatch('lottery/getLotteryTypeItems', 'liangMian')
      break

    // 殺號計畫
    case 'm-shjh-lottery_code':
    case 'shjh-lottery_code':
      app.$store.dispatch('lottery/getLotteryTypeItems', 'shaHao')
      break

    // 競猜
    case 'm-lotterybet-lotteryhome-lottery-mylotterybet':
    case 'm-lotterybet-lotteryhome-lottery-mysubscription':
    case 'm-lotterybet-lotteryhome-lottery-newlotterybet':
    case 'm-lotterybet-lotteryhome-lottery-rank':
    case 'm-lotterybet-lotteryhome-lottery-userinfo-id':
    case 'lotterybet-lotteryhome-lottery-mylotterybet':
    case 'lotterybet-lotteryhome-lottery-mysubscription':
    case 'lotterybet-lotteryhome-lottery-newlotterybet':
    case 'lotterybet-lotteryhome-lottery-rank':
    case 'lotterybet-lotteryhome-lottery-userinfo-id':
      app.$store.dispatch('lottery/getLotteryTypeItems', 'jingCai')
      break

    // 專家
    case 'm-expert-lottery_code':
    case 'expert-lottery_code':
      app.$store.dispatch('lottery/getLotteryTypeItems', 'zhuanJia')
      break

    // 購彩計畫
    case 'm-buyplan-lottery_code':
    case 'buyplan-lottery_code':
      app.$store.dispatch('lottery/getLotteryTypeItems', 'gouCai')
      break

    // 走勢
    case 'lottery-trend-lottery_code':
      app.$store.dispatch('lottery/getLotteryTypeItems')
      break

    default:
      console.warn('dispatchLotteryList: routeName 對應失敗!', routeName)
      break
  }
}

// 預測相關頁面的彩種選擇器的整理function
export const formatPredictionLotteryList = function(map, link, { isLotteryBetWeb = false, currentLottery = '' } = {}) {
  const groupList = Object.keys(map)
    .sort((a, b) => map[a].sort - map[b].sort) // 依照map 項目裡的sort 由小到大排序
    .reduce((array, lotteryType) => {
      const typeItem = {
        label: `${lotteryTypeNameMapping[lotteryType]} 系列`,
        groupKey: lotteryType,
        children: map[lotteryType].list.map((lottery) => {
          if (isLotteryBetWeb) {
            const temp = link.replace(currentLottery, lottery.lotteryCode)
            return Object.assign({}, lottery, { link: temp })
          }
          if (link) {
            return Object.assign({}, lottery, { link: `${link}/${lottery.lotteryCode}` })
          }
          return Object.assign({}, lottery)
        })
      }
      array.push(typeItem)

      return array
    }, [])
  return groupList
}

export const normalizeLotteryType = function(cpType) {
  switch (cpType) {
    case 'syx5':
      return '11x5' // different!
    case 'k3':
      return 'k3'
    case 'pk10':
      return 'pk10'
    case 'xy28':
      return 'xy28'
    case 'ssc':
      return 'ssc'
    case 'kl10':
      return 'kl10'
    case 'kl8':
      return 'kl8'
    case 'sixhc':
      return 'hk6' // different!
    case 'normal':
      return 'others' // different!
    default:
      console.warn('getLotteryType: 後端的cpType 對應失敗! 將回傳others.', cpType)
      return 'others'
  }
}
