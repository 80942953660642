import { renewToken_mobile } from '@/api/mobile/betLottery'
import { Dialog } from 'vant'
export const setLoginInfo = (payload) => {
  // 處理ssr 沒有 localStorage
  if (typeof localStorage === 'undefined') {
    return
  }
  localStorage.setItem('loginInfo', JSON.stringify(payload))
}

export const removeLoginInfo = () => {
  // 處理ssr 沒有 localStorage
  if (typeof localStorage === 'undefined') {
    return
  }
  localStorage.removeItem('loginInfo')
}

export const hasToken = () => {
  // 處理ssr 沒有 localStorage
  if (typeof localStorage === 'undefined') {
    return false
  }

  const member = JSON.parse(localStorage.getItem('loginInfo'))
  return member && member.token
}

export const getToken = () => {
  // 處理ssr 沒有 localStorage
  if (typeof localStorage === 'undefined') {
    return ''
  }
  return JSON.parse(localStorage.getItem('loginInfo')).token
}

export const getLoginInfo = () => {
  // 處理ssr 沒有 localStorage
  if (typeof localStorage === 'undefined') {
    return null
  }
  return JSON.parse(localStorage.getItem('loginInfo'))
}

export const checkIsLogin = async(vue) => {
  const member = getLoginInfo()
  if (member) {
    const [data, error] = await renewToken_mobile()
    if (error) {
      Dialog.alert({
        message: '会员验证过期，将跳转至登录页面'
      }).then(() => {
        vue.$router.push('/m/member/login')
      })
      return false
    } else {
      member.token = data
      setLoginInfo(member)
      return true
    }
  } else {
    Dialog.alert({
      message: '尚未登录，将跳转至登录页面'
    }).then(() => {
      vue.$router.push('/m/member/login')
    })
    return false
  }
}

export const checkIsLogin_web = async(vue) => {
  const member = getLoginInfo()
  if (member) {
    const [data, error] = await renewToken_mobile()
    if (error) {
      vue.$msgbox('会员验证过期，将跳转至登录页面', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          vue.$router.push('/login?mode=login')
        }
      })
      return false
    } else {
      member.token = data
      setLoginInfo(member)
      return true
    }
  } else {
    vue.$msgbox('尚未登录，将跳转至登录页面', '提示', {
      confirmButtonText: '确定'
    }).then(res => {
      vue.$router.push('/login?mode=login')
    }).catch(err => {
      console.log(err)
      vue.$router.push('/login?mode=login')
    })
    return false
  }
}

export const setUserInfo = (payload) => {
  if (typeof localStorage === 'undefined') {
    return
  }
  localStorage.setItem('userInfo', JSON.stringify(payload))
}

export const removeUserInfo = () => {
  if (typeof localStorage === 'undefined') {
    return
  }
  localStorage.removeItem('userInfo')
}
