import request from '@/utils/request'

// 取得所有支援的彩種列表
export const getPublicCp = function() {
  return request({
    url: '/v1/public/cp',
    method: 'get'
  })
}

// 取得計畫的彩種列表
export const getJiHuaCp = function(jihuaType) {
  return request({
    url: '/v1/cp168/wap/jihua/cps',
    method: 'get',
    params: { jihuaType }
  })
}

// 即時開獎 (支援從後台設定)
export const getCpsDetail = function(query) {
  return request({
    url: '/v1/cp168/apis/draw/infoDetail',
    method: 'get',
    params: query
  })
}
