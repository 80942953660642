<template>
  <div class="nav">
    <div class="nav__logo">
      <!-- for 首頁   -->
      <nuxt-link v-if="routeName === 'm'" to="./m">
        {{ $store.state.site.siteInfo.siteName }}
      </nuxt-link>
      <!-- for 图库 -->
      <span v-if="routeName.indexOf('images-liuHe') > 0">六合图库</span>
      <span v-if="routeName.indexOf('images-pk10') > 0">PK拾图库</span>
      <!-- for 競猜 -->
      <span v-if="routeName.indexOf('lotteryhome') > 0">竞猜中心</span>
      <!-- for 开奖号码   -->
      <span v-if="routeName ==='m-lottery-group-group_id'">开奖号码</span>

      <!-- for 直播开奖   -->
      <span v-if="routeName ==='m-video-lottery_code'">直播开奖</span>

      <a v-if="routeName === 'm-expert-lottery_code-detail'" class="expert-detail">
        <a class="fn-goback" @click="$router.go(-1)">
          <img src="@/assets/style/img/nav/iconReturn@2x.png" alt="找不到圖片！">
        </a>
        <span class="nav-dropdown__title expert-title">{{ expertDetailName }}</span>
      </a>

      <!-- for 走勢   -->
      <a v-if="routeName === 'm-lottery-trend-lottery_code'" class="nav-dropdown" @click="openAllLotteryPopUp">
        <span class="nav-dropdown__title">{{ lotteryName }}</span>
        <img class="nav-dropdown__img" src="@/assets/style/img/nav/chevron-down-solid.png" :class="{'change-direction': navMenuIsActive}">
      </a>

      <!-- for 預測頁面: 專家 兩面 殺號 競猜 長龍 -->
      <a v-if="isPredictPage" class="nav-dropdown">
        <div @click="menuToggleActive">
          <span class="nav-dropdown__title" v-text="currentMenu"></span>
          <img class="nav-dropdown__img" src="@/assets/style/img/nav/chevron-down-solid.png" :class="{'change-direction': navMenuIsActive}">
        </div>
      </a>

    </div>

    <!-- 彩種選擇: 預測頁面 -->
    <!-- <div v-if="false" class="nav__right">
      <a class="explain" @click="openAllLotteryPopUp">彩种</a>
    </div> -->
    <div v-if="isTrend" class="nav__right">
      <a class="explain" @click="openPopUp">说明</a>
    </div>

    <!-- 說明: 走勢 -->
    <!-- <div v-if="routeName === 'm-lottery-trend-lottery_code'" class="nav__right" :class="{'hide': explainIsActive}">
      <a class="explain" @click="openPopUp">说明</a>
    </div> -->

    <!-- 點開來的選单: 平時是隱藏的, 用於預測相關頁面 -->
    <div class="nav-menu" :class="{'nav-menu-active': navMenuIsActive, 'fn-safe-area-margin': isPredictPage}">
      <div class="menu-list">
        <a
          v-for="option in filterMenuOption"
          :key="`options-${option.label}`"
          href="javascript:;"
          class="menu-list__item"
          @click="clickMenuOption(option)"
        >
          <img
            class="item-img"
            :src="option.icon"
            alt=""
          >
          <span class="item-text" v-text="option.label"></span>
        </a>
      </div>
    </div>

    <!-- 彩票選擇器: 根據不同的頁面會有不同的結果 -->
    <pop-up-content
      :show.sync="showAllLottery"
      :auto-redirect="lotterySwitcher.autoRedirect"
      :options="lotterySwitcher.options"
      @switchLottery="switchLottery($event)"
    />
  </div>
</template>

<script>
import PopUpContent from '@/components/mobile/layout/PopupContent'

export default {
  name: 'WapHeader',
  components: {
    PopUpContent
  },
  data() {
    return {
      routeName: '',
      navMenuIsActive: false,
      lotteryName: '',
      lotteryCode: '',
      explainIsActive: false,
      showAllLottery: false,

      menuOptions: [{
        label: '两面推荐',
        icon: require('@/assets/style/img/index-co168/recommend-lot--icon1@2x.png'),
        link: '/m/lmTj/jspk10'
      }, {
        label: '专家推荐',
        icon: require('@/assets/style/img/index-co168/recommend-lot--icon2@2x.png'),
        link: '/m/expert/jspk10'
      }, {
        label: '长龙提醒',
        icon: require('@/assets/style/img/index-co168/recommend-lot--icon3@2x.png'),
        link: '/m/changltx'
      }, {
        label: '杀号计划',
        icon: require('@/assets/style/img/index-co168/recommend-lot--icon4@2x.png'),
        link: '/m/shjh/jspk10'
      }, {
        label: '购彩计划',
        icon: require('@/assets/style/img/index-co168/recommend-lot--icon5@2x.png'),
        link: '/m/buyplan/jspk10'
      }, {
        label: '彩票软件',
        icon: require('@/assets/style/img/index-co168/recommend-lot--icon6@2x.png'),
        link: '/m'
      }],

      lotterySwitcher: {
        autoRedirect: true,
        options: undefined
      }
    }
  },
  computed: {
    expertDetailName() {
      return this.$store.state.expert.expertDetailName
    },
    currentMenu() {
      const routeName = this.$route.name.toLowerCase()
      switch (routeName) {
        case 'm-shjh-lottery_code': // 殺號計畫
          return '杀号计划'
        case 'm-lmtj-lottery_code': // 兩面推薦
          return '两面推荐'
        case 'm-changltx':
          return '长龙提醒'
        case 'm-buyplan-lottery_code':
          return '购彩计划'
        case 'm-expert-lottery_code':
          return '专家推荐'
        default:
          return ''
      }
    },
    isPredictPage() {
      // 用於顯示右上角的彩種項目，目前先關閉
      const routeName = this.$route.name.toLowerCase()
      switch (routeName) {
        case 'm-shjh-lottery_code': // 殺號計畫
        case 'm-lmtj-lottery_code': // 兩面推薦
        case 'm-expert-lottery_code': // 專家推薦
        case 'm-changltx':
        case 'm-buyplan-lottery_code':
          return true
        default:
          return false
      }
    },
    filterMenuOption() {
      const routeName = this.$route.name.toLowerCase()
      switch (routeName) {
        case 'm-lmtj-lottery_code': // 兩面推薦
          return this.menuOptions.filter(option => option.label !== '两面推荐' && option.label !== '彩票软件')
        case 'm-expert-lottery_code':// 專家
          return this.menuOptions.filter(option => option.label !== '专家推荐' && option.label !== '彩票软件')
        case 'm-changltx': // 兩面推薦
          return this.menuOptions.filter(option => option.label !== '长龙提醒' && option.label !== '彩票软件')
        case 'm-shjh-lottery_code':
          return this.menuOptions.filter(option => option.label !== '杀号计划' && option.label !== '彩票软件')
        case 'm-buyplan-lottery_code':
          return this.menuOptions.filter(option => option.label !== '购彩计划' && option.label !== '彩票软件')
        default:
          return ''
      }
    },
    // 判斷是否為走勢頁路由
    isTrend() {
      return this.$route.path.includes('/trend')
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        const [lotteryNameMapping, error] = await this.$store.dispatch('lottery/getLotteryNameMapping')
        if (error) {
          console.warn('取得lotteryNameMapping 失敗!', error)
          return
        }

        this.routeName = this.$route.name
        this.lotteryCode = this.$route.params.lottery_code
        this.lotteryName = lotteryNameMapping[this.lotteryCode]
        this.handleExplain()

        this.assignLotterySwitcher(this.routeName)
        this.changeHeadTitle()
      }
    },
    '$route.params.lottery_code': {
      async handler(newVal, oldVal) {
        if (this.routeName !== 'm-lottery-trend-lottery_code') return
        const lotteryType = await this.$getLotteryType(newVal)

        if (lotteryType === 'others') {
          if (newVal === 'fc3d' || newVal === 'pl3' || newVal === 'shssc') {
            this.explainIsActive = false
          } else {
            this.explainIsActive = true
          }
        } else if (lotteryType === 'hk6') {
          this.explainIsActive = true
        }
      }
    }
  },
  methods: {
    changeHeadTitle() {
      const routeName = this.$route.name.toLowerCase()
      switch (routeName) {
        case 'm-shjh-lottery_code':
        case 'm-lmtj-lottery_code':
        case 'm-changltx':
        case 'm-buyplan-lottery_code':
        case 'm-expert-lottery_code':
          this.$store.commit('site/SET_TITLE', this.currentMenu + ' |')
          break
        case 'm-lottery-group-group_id':
          this.$store.commit('site/SET_TITLE', '开奖号码' + ' |')
          break
        case 'm-lotterybet-lotteryhome-lottery-rank':
          this.$store.commit('site/SET_TITLE', '竞猜中心' + ' |')
          break
        case 'm-lottery-trend-lottery_code':
          this.$store.commit('site/SET_TITLE', this.lotteryName + ' |')
          break
      }
    },
    menuToggleActive() {
      this.navMenuIsActive = !this.navMenuIsActive
    },
    async openPopUp() {
      const params = {
        type: await this.$getLotteryType(this.lotteryCode),
        popupIsOpen: true
      }
      this.$emit('lotteryInfo', params)
    },
    openAllLotteryPopUp() {
      this.showAllLottery = true
    },
    async handleExplain() {
      if (this.routeName !== 'm-lottery-trend-lottery_code') return
      const lotteryType = await this.$getLotteryType(this.lotteryCode)

      if (lotteryType === 'others') {
        if (this.lotteryCode === 'fc3d' || this.lotteryCode === 'pl3' || this.lotteryCode === 'shssc') {
          this.explainIsActive = false
        } else {
          this.explainIsActive = true
        }
      } else if (lotteryType === 'hk6') {
        this.explainIsActive = true
      }
    },

    clickMenuOption(option) {
      this.navMenuIsActive = false
      this.$router.push(option.link)
    },
    assignLotterySwitcher(routeName) {
      let list
      let autoRedirect = true

      switch (routeName) {
        case 'm-shJh-lottery_code':
          list = this.shJhLotteryList
          break
        case 'm-lmTj-lottery_code':
          list = this.lmtjLotteryList
          break
        case 'm-expert-lottery_code':
          list = this.expertLotteryList
          break
        case 'm-lotterybet-lotteryhome-lottery-mylotterybet':
        case 'm-lotterybet-lotteryhome-lottery-mysubscription':
        case 'm-lotterybet-lotteryhome-lottery-newlotterybet':
        case 'm-lotterybet-lotteryhome-lottery-rank':
          list = this.lotterybetLotterylist
          autoRedirect = false
          break
        default:
          list = undefined
      }
      this.lotterySwitcher.options = list
      this.lotterySwitcher.autoRedirect = autoRedirect
    },
    switchLottery(lotteryInfo) {
      const { lotteryCode } = lotteryInfo
      let link = ''
      switch (true) {
        case /^\/m\/lotterybet\/lotteryhome\/\w+\/mylotterybet$/.test(this.$route.path):
          link = '/m/lotterybet/lotteryhome/lotteryCode/mylotterybet'
          this.$router.push(link.replace('lotteryCode', lotteryCode))
          break
        case /^\/m\/lotterybet\/lotteryhome\/\w+\/mysubscription$/.test(this.$route.path):
          link = '/m/lotterybet/lotteryhome/lotteryCode/mysubscription'
          this.$router.push(link.replace('lotteryCode', lotteryCode))
          break
        case /^\/m\/lotterybet\/lotteryhome\/\w+\/newlotterybet$/.test(this.$route.path):
          link = '/m/lotterybet/lotteryhome/lotteryCode/newlotterybet'
          this.$router.push(link.replace('lotteryCode', lotteryCode))
          break
        case /^\/m\/lotterybet\/lotteryhome\/\w+\/rank$/.test(this.$route.path):
          link = '/m/lotterybet/lotteryhome/lotteryCode/rank'
          this.$router.push(link.replace('lotteryCode', lotteryCode))
          break
      }
    }
  },
  head() {
    return {
      titleTemplate: `${this.$store.state.site.title} %s`
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-menu {
    display: none;
  }
  .nav-menu-active {
    display: block;
  }
  .change-direction {
    transform: rotate(180deg);
    transition: .3s;
  }
  .hide {
    display: none;
  }
  .expert-detail {
    width:100%;
  }
  .fn-goback {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .expert-title {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  a {
    color: white;
  }
  .nav-dropdown {
    display: flex;
    align-items: center;
    &__title {
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
      & + .nav-dropdown__img {
        margin-left: 8px;
      }
    }
    &__img {
      flex: none;
    }
  }
</style>
