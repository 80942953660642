<template>
  <div class="container__header">
    <div class="statusbar">
      <div class="wrapper">
        <div class="statusbar__box">
          <div class="statusbar__box__left">
            <div class="marquee">
              <i class="marquee__icon">
                <img src="@/assets/desktop/img/marquee-icon.svg" alt>
              </i>
              <span class="marquee__title">最新公告：</span>
              <marquee
                class="marquee__message"
                direction="left"
                behavior="scroll"
                scrollamount="6"
                onmouseover="this.stop()"
                onmouseout="this.start()"
              >
                <span class="text">{{ marquee }}</span>
              </marquee>
            </div>
          </div>
          <div class="statusbar__box__right">
            <client-only>
              <template v-if="isLogin">
                <div class="signin-after">
                  <div class="member">
                    <div class="member__list">
                      <div
                        ref="member-list-face"
                        class="member__list__face"
                        :style="{ backgroundImage: avatarURL }"
                      ></div>
                      <span class="member__list__name">{{
                        userInfo.userName
                      }}</span>
                      <i class="member__list__hovericon"></i>
                    </div>
                    <div class="member__information">
                      <div class="member__information__item">
                        <div class="list-number">
                          <span class="list-number__title">积分：</span>
                          <span class="list-number__number">{{
                            userInfo.score
                          }}</span>
                        </div>
                        <div class="list-number">
                          <span class="list-number__title">粉丝：</span>
                          <span class="list-number__number">{{
                            userInfo.fansCount
                          }}</span>
                        </div>
                      </div>
                      <div class="member__information__item">
                        <a class="togo" @click="$router.push('/member')">
                          <span class="togo__text">前往个人页</span>
                          <i class="togo__icon"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <a class="signin-after__item" @click="logout">登出</a>
                  <a
                    class="signin-after__item"
                    @click="$router.push('/feedback')"
                  >意见反馈</a>
                </div>
              </template>
              <template v-else>
                <div class="signin-before">
                  <a
                    class="signin-before__item"
                    @click="$router.push('/login?mode=register')"
                  >注册</a>
                  <a
                    class="signin-before__item"
                    @click="$router.push('/login?mode=login')"
                  >登录</a>
                  <a
                    class="signin-before__item"
                    @click="$router.push('/feedback')"
                  >意见反馈</a>
                </div>
              </template>
            </client-only>
            <a class="mobile-link" href="/m">手机版</a>
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="wrapper">
        <div class="header__box">
          <nuxt-link class="logo" to="/">
            <img :src="logo.imgpath" alt="logo图">
          </nuxt-link>
          <div class="h-banner">
            <img
              v-if="headBanner.imgpath"
              :src="headBanner.imgpath"
              alt="headBanner图"
            >
          </div>
          <!-- <div v-if="qrcode" class="qr-link">
            <img
              class="qr-link__icon"
              src="@/assets/desktop/img/qr-icon.svg"
              alt
            />
            <div class="qr-link__text">
              <span class="text">扫一扫</span>
              <span class="text">APP下载</span>
            </div>
            <div class="qr-link__scanning">
              <div class="qr-link__scanning__img">
                <img :src="qrcode" alt />
              </div>
              <span class="qr-link__scanning__text">扫一扫,下载移动客户端</span>
              <span class="qr-link__scanning__text"
                >（目前仅供安卓下载, iOS敬请期待）</span
              >
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="headermenubar">
      <div class="wrapper">
        <ul class="headermenubar__box">
          <li class="menu-item" :class="{ active: activedMenu === 'home' }">
            <nuxt-link class="menu-item__link" to="/">
              <span class="menu-item__text">首页</span>
            </nuxt-link>
          </li>
          <li
            class="menu-item"
            :class="{ active: activedMenu === 'lottery-trend-content' }"
            @mouseover="subBoxActive = true"
            @mouseleave="subBoxActive = false"
          >
            <a class="menu-item__link">
              <span class="menu-item__text">彩票大厅</span>
              <i class="menu-item__icon"></i>
            </a>
            <div
              v-show="subBoxActive"
              class="menu-item__subbox"
              style="display: block"
            >
              <div class="wrapper">
                <div class="header-content">
                  <div
                    v-for="(item, index) in cpyList"
                    :key="index"
                    class="header-content__item"
                  >
                    <div class="header-content__item__left">
                      <img class="h-content-icon" :src="item.src" alt="">
                      <span class="h-content-title">{{ item.label }}</span>
                    </div>
                    <div class="header-content__item__right">
                      <a
                        v-for="child in item.children"
                        :key="`彩票大厅-${child.lotteryCode}`"
                        class="button-primary"
                        :href="child.natureLink"
                        @click.prevent="
                          goto({
                            link: `/lottery/trend/${child.lotteryCode}`,
                            lotteryCode: child.lotteryCode,
                            lotteryName: child.name
                          })
                        "
                      >
                        <span class="button-primary__text">{{
                          child.name
                        }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            class="menu-item"
            :class="{ active: activedMenu === 'images' }"
            @mouseover="subPicxActive = true"
            @mouseleave="subPicxActive = false"
          >
            <a class="menu-item__link">
              <span class="menu-item__text">图库</span>
              <i class="menu-item__icon"></i>
            </a>
            <div
              v-show="subPicxActive"
              class="menu-item__subbox"
              style="display: block"
            >
              <div class="wrapper">
                <div class="header-tabs">
                  <div
                    v-for="pic in picInfo.list"
                    :key="`pic-${pic.key}`"
                    class="header-tabs__item"
                    :class="{
                      active: pic.key === picInfo.currentActive
                    }"
                    @mouseover="picMouseout(pic)"
                    @click="gotoPic(pic)"
                  >
                    <span class="menu-item__text" v-text="pic.label"></span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            class="menu-item"
            :class="{ active: activedMenu === 'prediction' }"
            @mouseover="subBoxActive2 = true"
            @mouseleave="subBoxActive2 = false"
          >
            <a class="menu-item__link">
              <span class="menu-item__text">预测推荐</span>
              <i class="menu-item__icon"></i>
            </a>
            <div
              v-show="subBoxActive2"
              class="menu-item__subbox"
              style="display: block"
            >
              <div class="wrapper">
                <div class="header-tabs">
                  <div
                    v-for="prediction in predictionInfo.list"
                    :key="`prediction-${prediction.key}`"
                    class="header-tabs__item"
                    :class="{
                      active: prediction.key === predictionInfo.currentActive
                    }"
                    @mouseover="predictionMouseout(prediction)"
                  >
                    <span
                      class="header-tabs__item__text"
                      v-text="prediction.label"
                    ></span>
                  </div>
                </div>
                <div class="header-content">
                  <div
                    v-for="predictionLottery in predictionLotteryList"
                    :key="`predictionLottery-${predictionLottery.groupKey}`"
                    class="header-content__item"
                  >
                    <div class="header-content__item__left">
                      <img
                        class="h-content-icon"
                        src="@/assets/desktop/img/series.svg"
                        alt
                      >
                      <span
                        class="h-content-title"
                        v-text="predictionLottery.label"
                      ></span>
                    </div>
                    <div class="header-content__item__right">
                      <a
                        v-for="lottery in predictionLottery.children"
                        :key="
                          `predictionLottery-${predictionLottery.groupKey}-lottery-${lottery.lotteryCode}`
                        "
                        class="button-primary"
                        :href="lottery.link"
                        @click="goto({ link: lottery.link })"
                      >
                        <span
                          class="button-primary__text"
                          v-text="lottery.label"
                        ></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="menu-item" :class="{ active: activedMenu === 'changltx' }">
            <nuxt-link class="menu-item__link" to="/changltx">
              <span class="menu-item__text">长龙提醒</span>
            </nuxt-link>
          </li>
          <li
            class="menu-item"
            :class="{ active: activedMenu === 'lottery-trend' }"
          >
            <nuxt-link class="menu-item__link" to="/lottery/trend">
              <span class="menu-item__text">走势图表</span>
            </nuxt-link>
          </li>
          <li class="menu-item" :class="{ active: activedMenu === 'transfer' }">
            <nuxt-link class="menu-item__link" to="/transfer">
              <span class="menu-item__text">开奖调用</span>
            </nuxt-link>
          </li>
          <li
            class="menu-item"
            :class="{ active: activedMenu === 'lottery-bet' }"
          >
            <nuxt-link
              class="menu-item__link"
              to="/lotterybet/lotteryhome/jspk10/rank"
            >
              <span class="menu-item__text">竞猜中心</span>
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getCpsList_desktop } from '@/api/desktop/basic'
import { getUserInfo_web } from '@/api/desktop/member'
import get from 'lodash.get'

import { predictionsLotteryList } from '@/utils/flyc-temp/lotteryLib'

export default {
  name: 'WebHeader',
  data() {
    return {
      headBanner: {},
      logo: {},
      topAd: {},
      bottomAd: {},
      qrcode: null,
      marquee: '',
      cpyList: [],

      predictionInfo: {
        currentActive: 'lmTj',
        list: [
          {
            label: '两面推荐',
            key: 'lmTj'
          },
          {
            label: '专家推荐',
            key: 'expert'
          },
          {
            label: '购彩计划',
            key: 'buyPlan'
          },
          {
            label: '杀号计划',
            key: 'shJh'
          }
        ]
      },
      picInfo: {
        currentActive: '',
        list: [
          {
            label: '六合',
            key: 'liuHe'
          },
          {
            label: 'PK拾',
            key: 'pk10'
          }
        ]
      },
      avatarURL: '',
      subBoxActive: false,
      subBoxActive2: false,
      subPicxActive: false,
      defaultAvatar: require('@/assets/style/img/member/guesticon@2x.png')
    }
  },
  computed: {
    ...predictionsLotteryList,
    siteInfo() {
      return this.$store.state.site.webSiteInfo
    },
    predictionLotteryList() {
      switch (this.predictionInfo.currentActive) {
        case 'lmTj':
          return this.lmtjLotteryList
        case 'expert':
          return this.expertLotteryList
        case 'buyPlan':
          return this.buyplanLotteryList
        case 'shJh':
          return this.shJhLotteryList
        default:
          console.warn(
            'predictionLotteryList: 預測頁面對應失敗!',
            this.predictionInfo.currentActive
          )
          return []
      }
    },
    isLogin() {
      return this.$store.state.member.isLogin
    },
    userInfo() {
      return this.$store.state.member.memberInfo
    },

    routerName() {
      return this.$route.name ? this.$route.name.toLowerCase() : ''
    },
    routerParams() {
      return this.$route.params ? this.$route.params : {}
    },
    activedMenu() {
      const { routerName } = this

      switch (routerName) {
        // 首頁
        case 'index':
          return 'home'

        // 彩票大廳
        case 'lottery-trend-lottery_code':
          return 'lottery-trend-content'

        // 預測推薦
        case 'lmtj-lottery_code':
        case 'expert-lottery_code':
        case 'buyplan-lottery_code':
        case 'shjh-lottery_code':
          return 'prediction'

        // 長龍提醒
        case 'changltx':
          return 'changltx'

        // 走勢圖表
        case 'lottery-trend':
          return 'lottery-trend'

        // 開獎調用
        case 'transfer':
          return 'transfer'

        // 競猜中心
        case 'lotterybet-lotteryhome-lottery_code-mylotterybet':
        case 'lotterybet-lotteryhome-lottery_code-mysubscription':
        case 'lotterybet-lotteryhome-lottery_code-newlotterybet':
        case 'lotterybet-lotteryhome-lottery_code-rank':
        case 'lotterybet-lotteryhome-lottery_code-userinfo-id':
          return 'lottery-bet'

        default:
          return ''
      }
    },
    currentTabName() {
      const { routerParams } = this

      switch (this.routerName) {
        case 'transfer':
          return '开奖调用'
        case 'lottery-trend-lottery_code':
          return this.$store.state.lottery.all.lotteryNameMapping[
            routerParams.lottery_code
          ]
        case 'lottery-trend':
          return '走势图表'
        case 'changltx':
          return '长龙提醒'
        case 'lotterybet-lotteryhome-lottery_code-rank':
          return '竞猜中心'
        case 'lmtj-lottery_code':
          return '两面推荐'
        case 'expert-lottery_code':
          return '专家推荐'
        case 'buyplan-lottery_code':
          return '购彩计划'
        case 'shjh-lottery_code':
          return '杀号计划'
        case 'feedback':
          return '意见反馈'
        case 'login':
          return '我的'
        default:
          return ''
      }
    }
  },
  watch: {
    userInfo: {
      immediate: true,
      deep: true,
      handler(newV) {
        this.getAvatar(this.userInfo.avatarURL)
      }
    },
    isLogin: {
      immediate: true,
      async handler(newV) {
        if (newV) await this.getUserInfo()
      }
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.changeHeadTitle()
      }
    }
  },
  created() {
    this.headBanner = get(this, 'siteInfo.siteSetting.headBanner', {})
    this.logo = get(this, 'siteInfo.siteSetting.logo', {})
    this.marquee = get(this, 'siteInfo.siteSetting.marquee', '')
    this.qrcode = this.$store.state.site.siteInfo.siteSetting.apkLink
    this.topAd = get(this, 'siteInfo.siteSetting.topAd', {})
    this.bottomAd = get(this, 'siteInfo.siteSetting.bottomAd', {})
      ? get(this, 'siteInfo.siteSetting.apkLink.qrCode', null)
      : ''
    this.getCpsList()

    // 取得預測頁面的連結
    this.$store.dispatch('lottery/getLotteryTypeItems', 'liangMian')
    this.$store.dispatch('lottery/getLotteryTypeItems', 'shaHao')
    this.$store.dispatch('lottery/getLotteryTypeItems', 'jingCai')
    this.$store.dispatch('lottery/getLotteryTypeItems', 'zhuanJia')
    this.$store.dispatch('lottery/getLotteryTypeItems', 'gouCai')

    // 為了確保取得彩種名稱 mapping 表
    this.$store.dispatch('lottery/getLotteryNameMapping')
  },
  mounted() {
    window.vm = this
  },
  methods: {
    changeHeadTitle() {
      switch (this.routerName) {
        case 'transfer':
        case 'lottery-trend':
        case 'changltx':
        case 'lotterybet-lotteryhome-lottery_code-rank':
        case 'lmtj-lottery_code':
        case 'expert-lottery_code':
        case 'buyplan-lottery_code':
        case 'shjh-lottery_code':
        case 'feedback':
        case 'login':
        case 'lottery-trend-lottery_code':
          this.$store.commit('site/SET_WEB_TITLE', this.currentTabName + ' |')
          break
        default:
          this.$store.commit('site/SET_WEB_TITLE', '')
          break
      }
    },
    async getCpsList() {
      let jisuCps, gaopinCps, localCps, chinaCps
      let jisuCpsError = null
      let gaopinCpsError = null
      let localCpsError = null
      let chinaCpsError = null

      jisuCps = getCpsList_desktop('jisu')
      gaopinCps = getCpsList_desktop('gaopin')
      localCps = getCpsList_desktop('local')
      chinaCps = getCpsList_desktop('china')
      ;[
        [jisuCps, jisuCpsError],
        [gaopinCps, gaopinCpsError],
        [localCps, localCpsError],
        [chinaCps, chinaCpsError]
      ] = await Promise.all([jisuCps, gaopinCps, localCps, chinaCps])
      if (jisuCpsError || gaopinCpsError || localCpsError || chinaCpsError) {
        return
      }

      this.cpyList = [
        {
          label: '极速彩',
          src: require('@/assets/desktop/img/speed.svg'),
          children: jisuCps
        },
        {
          label: '高频彩',
          src: require('@/assets/desktop/img/highfrequency.svg'),
          children: gaopinCps
        },
        {
          label: '地方彩',
          src: require('@/assets/desktop/img/local.svg'),
          children: localCps
        },
        {
          label: '全国彩',
          src: require('@/assets/desktop/img/nationwide.svg'),
          children: chinaCps
        }
      ].reduce((array, group) => {
        group.children = group.children.map((child) => {
          return (({ name, code: lotteryCode, cpType }) => {
            const natureLink = `/lottery/trend/${lotteryCode}`
            return {
              name,
              lotteryCode,
              natureLink
            }
          })(child)
        })
        return array.concat(group)
      }, [])
    },
    async goto(item) {
      if (item.lotteryName) {
        this.$store.commit('site/SET_WEB_TITLE', item.lotteryName + ' |')
      }
      this.$router.push(item.link)

      this.subBoxActive = false
      this.subBoxActive2 = false
    },
    predictionMouseout(prediction) {
      this.predictionInfo.currentActive = prediction.key
    },
    picMouseout(pic) {
      this.picInfo.currentActive = pic.key
    },
    async getUserInfo() {
      const [data, error] = await getUserInfo_web()
      if (error) {
        await this.$store.dispatch('member/removeLoginInfo')
        await this.$store.dispatch('member/removeUserInfo')
        return
      }
      await this.$store.dispatch('member/setUserInfo', data)
    },
    getAvatar(imageURL) {
      if (imageURL) {
        this.$nextTick(() => {
          const vm = this
          var bgImg = new Image()
          bgImg.onload = function() {
            vm.avatarURL = `url(${bgImg.src})`
          }
          bgImg.src = imageURL
        })
      } else {
        this.avatarURL = `url(${this.defaultAvatar})`
      }
    },
    logout() {
      this.$confirm('是否确定要登出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      })
        .then(() => {
          this.$store.dispatch('member/removeLoginInfo')
          this.$store.dispatch('member/removeUserInfo')
          this.$router.push('/')
          this.$message({
            type: 'success',
            message: '成功登出'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消動作'
          })
        })
    },
    gotoPic(pic) {
      this.$router.push(`/images/${pic.key}`)
      this.$store.commit('lottery/SET_DIRECT_ENTER_IMAGE', true) // 從選單直接進入圖庫，會設為 true
    }
  },
  head() {
    return {
      titleTemplate: `${this.$store.state.site.webTitle} %s`
    }
  }
}
</script>
