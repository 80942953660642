<template>
  <div v-if="descriptionState.descriptionType === 'prediction'" class="popup__box__content">
    <div class="article" v-html="defaultContent">
    </div>
  </div>
  <div v-else-if="descriptionState.descriptionType === 'lotteryItem'" class="popup__box__content">
    <div v-if="lotteryType === 'pk10'" class="article">
      <p class="article__title">1. 单球: 开出的号码</p>
      <p>◎大小: 大于或等于6为“大”，小于或等于5为“小”</p>
      <p>◎单双: 能被2整除的数为“双”，反之，为“单”</p>
      <p>◎前后: 号码开出在前5名为“前”，开出在后5名为“后”</p>
      <p class="article__title">2. 冠亚军和: 开奖结果冠军号码与亚军号码的和值</p>
      <p>◎大小: 大于11为大, 小于11为小, 等于11视为和</p>
      <p>◎单双: 能被2整除的数为“双”，反之，为“单”，若总和为11则视为“和”</p>
      <p class="article__title">3. 1-5龙虎</p>
      <p>冠军vs第十名</p>
      <p>◎龙：冠军号码大于第十名号码视为“龙”中奖</p>
      <p>◎虎：冠军号码小于第十名号码视为“虎”中奖</p>
      <p>亚军vs第九名</p>
      <p>◎龙：亚军号码大于第九名号码视为“龙”中奖</p>
      <p>◎虎：亚军号码小于第九名号码视为“虎”中奖</p>
      <p>季军vs第八名</p>
      <p>◎龙：季军号码大于第八名号码视为“龙”中奖</p>
      <p>◎虎：季军号码小于第八名号码视为“虎”中奖</p>
      <p>第四名vs第七名</p>
      <p>◎龙：第四名号码大于第七名号码视为“龙”中奖</p>
      <p>◎虎：第四名号码小于第七名号码视为“虎”中奖</p>
      <p>第五名vs第六名</p>
      <p>◎龙：第五名号码大于第六名号码视为“龙”中奖</p>
      <p>◎虎：第五名号码小于第六名号码视为“虎”中奖</p>
      <p class="article__title">4. “前”表示号码开出在前5名，“后”表示号码开出在后5名</p>
      <p class="article__title">5. 质合</p>
      <p>◎质数: 1、2、3、5、7</p>
      <p>◎合数: 0、4、6、8、9</p>
      <p class="article__title">6. 012路</p>
      <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
      <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
      <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
    </div>
    <div v-if="lotteryType === 'k3'" class="article">
      <p class="article__title">1. 百/十/个位</p>
      <p>◎ 大小 : 1、2、3为小，4、5、6为大</p>
      <p>◎ 单双：1、3、5为单，2、4、6为双</p>
      <p class="article__title">2. 总和</p>
      <p>◎大小: 三个开奖号码总和值11~17 为大；总和值4~10 为小；若三个号码相同、则为和 (平局)</p>
      <p>◎单双: 三个开奖号码总和5、7、9、11、13、15、17为单；4、6、8、10、12、14、16为双；若三个号码相同、则为和 (平局)</p>
      <p class="article__title">3. 鱼虾蟹又称鱼虾蟹骰宝, 下注形式跟骰子一样, 采用骰子由鱼, 虾, 蟹, 金钱, 胡芦及鸡代替点数</p>
      <p>一点: 鱼 (红色)</p>
      <p>二点: 虾 (绿色)</p>
      <p>三点: 葫卢 (蓝色)</p>
      <p>四点: 金钱 (蓝色)</p>
      <p>五点: 蟹 (绿色)</p>
      <p>六点: 鸡 (红色)</p>
      <p class="article__title">4. 质合</p>
      <p>◎质数: 1、2、3、5、7</p>
      <p>◎合数: 0、4、6、8、9</p>
      <p class="article__title">5. 012路</p>
      <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
      <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
      <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
      <p class="article__title">6. 号码形态</p>
      <p>◎ 豹子：中奖号码的三个数字都相同。如中奖号码为111、222、333等</p>
      <p>◎ 对子：中奖号码的任意两位数字相同。（不包括豹子）如中奖号码为121、112、211等</p>
      <p>◎ 三不同：不包括豹子、对子、的所有中奖号码，如为136、624等</p>
    </div>
    <div v-if="lotteryType === '11x5'" class="article">
      <p class="article__title">1. 单码两面 - 指第一球~第五球</p>
      <p>◎大小：开出的号码大于或等于6为“大”，小于或等于5为“小”，开出11为“和”</p>
      <p>◎单双：能被2整除的数为“双”，反之，为“单”，开出11为“和”</p>
      <p class="article__title">2. 总和: 所有开奖号码数字加总值</p>
      <p>◎ 大小: 大于30为“大”；总和值小于30为“小”；若总和值等于30为“和”</p>
      <p>◎单双: 能被2整除的数为“双”，反之，为“单”</p>
      <p>◎尾数大小: 大于或等于5为尾大，小于或等于4为尾小</p>
      <p class="article__title">3. 龙虎玩法:</p>
      <p>◎龙：第一球开奖号码大于第五球开奖号码</p>
      <p>◎虎：第一球开奖号码小于第五球开奖号码</p>
      <p class="article__title">4. 前三(万、千、百位), 中三 (千、百、十位), 后三(百、十、个位)</p>
      <p>◎豹子：中奖号码的三个数字都相同 ex 000、111、999</p>
      <p>◎顺子：中奖号码的三个数字都相连，不分顺序 ex 123、901、321、546</p>
      <p>◎对子：中奖号码三位任意两位数字相同（不包括豹子）ex 001，112、696</p>
      <p>◎半顺：中奖号码的三位上任意两位数字相连，不分顺序（不包括顺子、对子) ex 125、540、390、706</p>
      <p>◎杂六：三位开奖号码不包括豹子、对子、顺子、半顺的其他所有中奖号码 ex 157</p>
      <p class="article__title">5. 质合</p>
      <p>◎质数: 1、2、3、5、7等</p>
      <p>◎合数: 0、4、6、8、9等</p>
      <p class="article__title">6. 012路</p>
      <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
      <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
      <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
    </div>
    <div v-if="lotteryType === 'ssc'" class="article">
      <p class="article__title">1. 单码两面: 指第一球~第五球</p>
      <p>◎大小 : 5、6、7、8、9时为“大”，0、1、2、3、4时为“小”</p>
      <p>◎单双: 1、3、5、7、9时为“单”，0、2、4、6、8时为“双”</p>
      <p class="article__title">2. 总和: 加总当期开奖结果五个彩球号码</p>
      <p>◎总和大小: 0~22为小、23~45为大</p>
      <p>◎总和单双: 能被2整除的数为“双”，反之，为“单”</p>
      <p class="article__title">3. 1-5龙虎</p>
      <p>◎龙: 当期开奖结果“万位”号码大于“个位”的中奖号码。</p>
      <p>◎虎: 当期开奖结果“万位”号码小于“个位”的中奖号码。</p>
      <p>◎和: 当期开奖结果“万位”号码等于“个位”的中奖号码。</p>
      <p class="article__title">4. 前三(万、千、百位), 中三 (千、百、十位), 后三(百、十、个位)</p>
      <p>◎豹子：中奖号码的三个数字都相同 ex 000、111、999</p>
      <p>◎顺子：中奖号码的三个数字都相连，不分顺序 ex 123、901、321、546</p>
      <p>◎对子：中奖号码三位任意两位数字相同（不包括豹子）ex 001，112、696</p>
      <p>◎半顺：中奖号码的三位上任意两位数字相连，不分顺序（不包括顺子、对子) ex 125、540、390、706</p>
      <p>◎杂六：三位开奖号码不包括豹子、对子、顺子、半顺的其他所有中奖号码 ex 157</p>
      <p class="article__title">5. 质合</p>
      <p>◎质数: 1、2、3、5、7等</p>
      <p>◎合数: 0、4、6、8、9等</p>
      <p class="article__title">6. 012路</p>
      <p>◎ 0: 除3余数为0的为0路号码，包含3、6、9</p>
      <p>◎ 1: 除3余数为1的为1路号码，包含1、4、7、10</p>
      <p>◎ 2: 除3余数为2的为2路号码，包含2、5、8</p>
    </div>
    <div v-if="lotteryType === 'kl8'" class="article">
      <p class="article__title">1. 单球:</p>
      <p>◎大小 : 41~80时为“大”, 01~40时为“小”</p>
      <p>◎单双: 能被2整除则为“双”，否则为“单”</p>
      <p class="article__title">2. 总和: 所有20个开奖数字之和</p>
      <p>◎大、小：大于810则为“大”，小于810则为“小”，如果值为810则为“和”</p>
      <p>◎单、双：能被2整除则为“双，否则为“单”</p>
      <p class="article__title">3. 奇、偶盘 (单双):</p>
      <p>开出的20个中奖号码中, 如奇数号码数目占多数(超过10个)，则为奇盘(单多)，投注“奇”者中奖;偶数号码占多数(超过10个)，则为偶盘(双多)，投注“偶”者中奖;如果奇数和偶数号码数目相同时(均为 10个)，则为合(单双和)</p>
      <p class="article__title">4. 上、下盘 (前后)</p>
      <p>号码1至40为上盘号码，41至80为下盘号码。开出的20个开奖号码中：如上盘号码占多数(超过10个)时，此局为上盘(前多);下盘号码占多数时为下盘(后多);上下盘号码在此局开出的数目相同时(各10个数字)，此局为和盘</p>
      <p class="article__title">5. 五行，即总数五行</p>
      <p>开出的20个号码的总和分在5个段，以金、木、水、火、土命名：金(210～695)、木(696～763)、水(764～855)、火(856～923)和土(924～1410)</p>
    </div>
    <div v-if="lotteryType === 'xy28'" class="article">
      <p class="article__title">1. 总和双面: 取当期开奖号码和值</p>
      <p>◎大小: 数字14-27为大；数字0-13为小</p>
      <p>◎单双:能被2整除的数为“双”，反之，为“单”</p>
      <p class="article__title">2. 波色: 取当期开奖号码和值</p>
      <p>绿波: 1，4，7，10，16，19，22，25</p>
      <p>蓝波: 2，5，8，11，17，20，23，26</p>
      <p>红波: 3，6，9，12，15，18，21，24</p>
      <p>和：0、13、14、27</p>
      <p class="article__title">3. 极值: 取当期开奖号码和值</p>
      <p>极小: 0，1，2，3，4，5</p>
      <p>极大: 22，23，24，25，26，27</p>
      <p>其余皆为和</p>
    </div>
    <div v-if="lotteryType === 'kl10'" class="article">
      <p class="article__title">1. 单球</p>
      <p>◎大小：开出号码大于等于11，为“大”，小于11，为“小”</p>
      <p>◎单双：能被2整除的数为“双”，反之，为“单”</p>
      <p class="article__title">2. 总和: 所有8个开奖数字之和</p>
      <p>◎大小：处于85~132之间，为“大”，等于84为“和”，小于84为“小”</p>
      <p>◎单双：能被2整除的数为“双”，反之，为“单”</p>
      <p>◎尾数大小: 开出号码的个位大于等于5，为“尾大”，小于或等于4为“尾小”</p>
      <p class="article__title">3. 龙虎</p>
      <p>• “第一球”的号码大于“第八球”的号码，为【龙】，反之为【虎】</p>
      <p>• “第二球”的号码大于“第七球”的号码，为【龙】，反之为【虎】</p>
      <p>• “第三球”的号码大于“第六球”的号码，为【龙】，反之为【虎】</p>
      <p>• “第四球”的号码大于“第五球”的号码，为【龙】，反之为【虎】</p>
      <p class="article__title">4. 合数单双: 每个球的十位数加个位数的和, 能被2整除的数为“双”，反之，为“单”</p>
      <p class="article__title">5. 中发白: 开出号码为</p>
      <p>中: 01、02、03、04、05、06、07</p>
      <p>发: 08、09、10、11、12、13、14</p>
      <p>白: 15、16、17、18、19、20</p>
      <p class="article__title">6. 东南西北: 开出号码为</p>
      <p>東: 01、05、09、13、17</p>
      <p>南: 02、06、10、14、18</p>
      <p>西: 03、07、11、15、19</p>
      <p>北: 04、08、12、16、20</p>
    </div>
    <div v-if="hasRulePopup" class="article">
      <p>总和: 所有3个开奖数字之和</p>
      <p>◎大小：14~27 为“大”，0~13 为“小”</p>
      <p>◎单双：能被2整除的数为“双”，反之，为“单”</p>
    </div>
  </div>
  <div v-else class="popup__box__content">
    <NoData />
  </div>
</template>

<script>
import NoData from '@/components/web/common/NoData'
export default {
  name: 'RulePopUp',
  components: {
    NoData
  },
  data() {
    return {
      lotteryCode: this.$route.params.lottery_code,
      lotteryType: ''
    }
  },
  computed: {
    descriptionState() {
      return this.$store.state.lottery.description
    },
    routeName() {
      return this.$route.name ? this.$route.name.toLowerCase() : ''
    },
    defaultContent() {
      switch (this.routeName) {
        // 殺號計畫
        case 'm-shjh-lottery_code':
        case 'shjh-lottery_code':
          return `
          <p>杀号: 专家认为当前不可能开出的号码。</p>
          <p>专家: 共5位专家每期提供一个他们认为在当期不可能出现的号码。</p>
          `

        // 競猜
        case 'lotterybet-lotteryhome-lottery_code-mylotterybet':
        case 'lotterybet-lotteryhome-lottery_code-mysubscription':
        case 'lotterybet-lotteryhome-lottery_code-newlotterybet':
        case 'lotterybet-lotteryhome-lottery_code-rank':
        case 'lotterybet-lotteryhome-lottery_code-userinfo-id':
          return `<p>01 竞猜中心简介</p>
      <p>本活动是玩家透过自行判断预测  [极速PK10][五分PK10] [北京PK10] [幸运飞艇] 的开奖结果, 竞猜正确获得更多积分，竞猜失败则损失投注的积分，增强玩家的参与性与判断度</p>
      <p>02 竞猜雷台规则</p>
      <p>1. 用户注册后，即可免费参与发布计划</p>
      <p>每周任一天登录即赠送1000积分，每日进入竞猜中心签到还可获得100积分</p>
      <p>3. 用户一周内必须发起4单竞猜以上，才能满足上排行榜的资格</p>
      <p>4. 每周一到日为一个排名周期，每周一00:00开始重新计算</p>
      <p>03 关注高手竞猜</p>
      <p>可在竞猜页面点击 [订阅] 即可免费关注与查看高手竞猜</p>
      <p>04 盈利如何计算</p>
      <p>本活动按照结算的金额判断输赢，每注成本为10个积分，并搭配PK10对应玩法的赔率进行结算，遇小数点无条件进位</p>
      <p>单双、大小、龙虎赔率为1.98</p>
      <p>号码 赔率为 9.85</p>
      <p>05 积分可以换成现金吗?</p>
      <p>本活动所有积分皆无法兑换成任何真实货币，本游戏仅供玩家参考</p>`

        // 購彩計畫
        case 'buyplan-lottery_code':
        case 'm-buyplan-lottery_code':
          return `
          <h4>功能介绍</h4>
          <p>依据各彩种历史大数据规律为基础，提供N组购彩计划参考，建议使用阶梯倍投方法投注，如果连续10期没有成功则标记为失败</p>
          <h4>本推荐玩法</h4>
          <p>追踪第一球开奖号码，以倍投的形式，第一期以1为基数，以此类推，连续十次不中则更新计划</p>
          <h4>使用技巧</h4>
          <p>建议使用，阶梯倍投方式追号投注</p>
          <h4>推荐计划</h4>
          <p>三种方案的推荐计划分别对应不同个数的号码，号码越多中奖率越高，但是中奖额度较小，风险 越大，收益越高。比如：计划1为5个号码，所需要的成本为5，假如赔率是9.85，则赢4.85; 如果是计划3， 有3个号码，成本为3, 则赢6.85</p>
          <h4>当前成本</h4>
          <p>指当前期购彩号码成本，每个计划的成本以号码多少为基准</p>
          <h4>累计成本</h4>
          <p>指从每日推荐第一期开始计算所用总成本</p>
          <h4>赢亏</h4>
          <p>根据试玩的赔率计算得来，红为赢，蓝为亏</p>
          `
        default:
          console.warn('RulePopup defaultContent: 沒有在switch case 裡，將使用空字串', this.routeName)
          return ''
      }
    },
    hasRulePopup() {
      return this.lotteryType === 'others' && (this.lotteryCode === 'fc3d' || this.lotteryCode === 'pl3' || this.lotteryCode === 'shssc')
    }
  },
  async created() {
    this.lotteryType = await this.$getLotteryType(this.lotteryCode)
  }
}
</script>
