<template>
  <div v-show="hasSideMenu" class="sidebar-menu">
    <div class="sidebar" fn-for-blur-event tabindex="0" @blur="menuBlur">
      <div class="sidebar__title">
        <span class="sidebar__title__text">选择彩种</span>
      </div>
      <div class="sidebar__content">
        <div
          v-for="group in list"
          :key="`group-${group.label}`"
          class="sidebar__content__item"
        >
          <div
            class="ultitle"
            :class="{ active: group.groupKey === currentVisitedGroup }"
            @click="changeGroup(group)"
          >
            <span class="ultitle__text">{{ group.label }}<i class="ultitle__text__subicon"></i></span>
          </div>

          <div v-show="group.groupKey === currentActiveGroup" class="libox">
            <div class="libox__content" fn-display-none>
              <div
                v-for="lottery in group.children"
                :key="`group-${group.label}-lottery-${lottery.lotteryCode}`"
                class="libox__content__item"
                @click="goto(lottery)"
              >
                <span
                  class="button-primary"
                  :class="{ active: lottery.lotteryCode === lotteryCode }"
                  fn-cursor-pointer
                >
                  <span class="button-primary__text" v-text="lottery.label"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { predictionsLotteryList, dispatchLotteryList } from '@/utils/flyc-temp/lotteryLib'

export default {
  name: 'SidebarMenu',
  props: {
    options: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      lotteryCode: '',
      lotteryType: '',
      currentActiveGroup: ''
    }
  },
  computed: {
    ...predictionsLotteryList,
    routeName() {
      return this.$route.name ? this.$route.name.toLowerCase() : ''
      // return this.$route.name.toLowerCase()
    },
    hasSideMenu() {
      switch (this.routeName) {
        // 殺號計畫
        // 兩面推薦
        // 競猜
        // 專家推薦
        // 購彩計畫
        case 'm-shjh-lottery_code':
        case 'shjh-lottery_code':
        case 'm-lmtj-lottery_code':
        case 'lmtj-lottery_code':
        case 'm-lotterybet-lotteryhome-lottery-mylotterybet':
        case 'm-lotterybet-lotteryhome-lottery-mysubscription':
        case 'm-lotterybet-lotteryhome-lottery-newlotterybet':
        case 'm-lotterybet-lotteryhome-lottery-rank':
        case 'm-lotterybet-lotteryhome-lottery-userinfo-id':
        case 'lotterybet-lotteryhome-lottery_code-mylotterybet':
        case 'lotterybet-lotteryhome-lottery_code-mysubscription':
        case 'lotterybet-lotteryhome-lottery_code-newlotterybet':
        case 'lotterybet-lotteryhome-lottery_code-rank':
        case 'lotterybet-lotteryhome-lottery_code-userinfo-id':
        case 'm-expert-lottery_code':
        case 'expert-lottery_code':
        case 'buyplan-lottery_code':
        case 'm-buyplan-lottery_code':
          return true
        default:
          return false
      }
    },
    defaultList() {
      if (!this.hasSideMenu) return []

      switch (this.routeName) {
        // 殺號計畫
        case 'm-shjh-lottery_code':
        case 'shjh-lottery_code':
          return this.shJhLotteryList

        // 兩面推薦
        case 'm-lmtj-lottery_code':
        case 'lmtj-lottery_code':
          return this.lmtjLotteryList

        // 競猜
        case 'm-lotterybet-lotteryhome-lottery-mylotterybet':
        case 'm-lotterybet-lotteryhome-lottery-mysubscription':
        case 'm-lotterybet-lotteryhome-lottery-newlotterybet':
        case 'm-lotterybet-lotteryhome-lottery-rank':
        case 'm-lotterybet-lotteryhome-lottery-userinfo-id':
          return this.lotterybetLotteryList
        case 'lotterybet-lotteryhome-lottery_code-mylotterybet':
        case 'lotterybet-lotteryhome-lottery_code-mysubscription':
        case 'lotterybet-lotteryhome-lottery_code-newlotterybet':
        case 'lotterybet-lotteryhome-lottery_code-rank':
        case 'lotterybet-lotteryhome-lottery_code-userinfo-id':
          return this.lotterybetLotteryList_web
        // 專家推薦
        case 'm-expert-lottery_code':
        case 'expert-lottery_code':
          return this.expertLotteryList

        // 購彩計畫
        case 'buyplan-lottery_code':
        case 'm-buyplan-lottery_code':
          return this.buyplanLotteryList

        // 走勢
        case 'lottery-trend-lottery_code':
          // return this.webLotteryTrendList
          return []
        default:
          if (!Array.isArray(this.options)) {
            console.warn('SidebarMenu: 沒有傳入options 也沒有在switch case 裡，將使用空陣列', this.routeName)
          }
          return []
      }
    },
    list() {
      if (Array.isArray(this.options)) {
        return this.options
      }
      return this.defaultList
    },

    currentVisitedGroup() {
      return this.lotteryType
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler() {
        if (!this.hasSideMenu) return
        await dispatchLotteryList(this)

        this.lotteryCode = this.$route.params.lottery_code
        this.lotteryType = this.lotteryCode ? await this.$getLotteryType(this.lotteryCode) : undefined
      }
    }
  },
  methods: {
    changeGroup(group) {
      this.currentActiveGroup = this.currentActiveGroup === group.groupKey ? '' : group.groupKey
    },
    goto(lottery) {
      if (typeof lottery.link !== 'string') {
        console.warn('SidebarMenu: 缺少lottery.link!', lottery)
        return
      }
      this.$router.push(lottery.link)
      this.currentActiveGroup = ''
    },
    menuBlur() {
      this.currentActiveGroup = ''
    }
  }
}
</script>

<style scoped>
  [fn-for-blur-event]:focus {
    outline-width: 0px;
  }
  [fn-cursor-pointer] {
    cursor: pointer;
  }
</style>
